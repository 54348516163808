// import React from 'react';
// import './DocumentYourContract.css'; // Import the CSS file
// import { useLocation } from 'react-router-dom';
// import axios from 'axios';

// const DocumentYourContract = () => {
//     const location = useLocation();
//     const { name, phone, total, reference_number, email } = location.state || {};

//     const handleSendSMS = () => {
//         const phoneNumber = '+966599489990';
//         const message = encodeURIComponent('Please ensure all required fields are completed accurately before submitting your contract documentation. This step is crucial for the verification process. Thank you for your cooperation. If you need assistance, please contact the Lahalak support team.');
//         window.location.href = `sms:${phoneNumber}?body=${message}`;
//     };
 
//     const handleSendWhatsApp = () => {
//         const phoneNumber = '+966599489990';
//         const message = encodeURIComponent('Please ensure all required fields are completed accurately before submitting your contract documentation. This step is crucial for the verification process. Thank you for your cooperation. If you need assistance, please contact the Lahalak support team.');
//         window.location.href = `https://wa.me/${phoneNumber}?text=${message}`;
//     }; 


//     const handleAmazonPay = async () => {

//     };

//     const logErrorDetails = (error) => {
//         console.error('Error creating checkout session:', error);
//         if (error.response) {
//             console.error('Error response data:', error.response.data);
//             console.error('Error response status:', error.response.status);
//             console.error('Error response headers:', error.response.headers);
//         } else if (error.request) {
//             console.error('Error request data:', error.request);
//         } else {
//             console.error('Error message:', error.message);
//         }
//         console.error('Error config:', error.config);
//     };


//     return (
//         <div className="document-your-contract">
//             <div className="content">
//                 <h1>اختر طريقة التوثيق</h1>
//                 <p>يرجى التأكد من صحة رقم الجوال المدخل.</p>
//                 <div className="buttonsa">
//                     <button onClick={handleSendSMS}>توثيق عبر رسالة نصية</button>
//                     <button onClick={handleSendWhatsApp}>توثيق عبر واتس آب</button>
//                     <button onClick={handleAmazonPay}>Pay with Amazon</button>
//                     <button onClick={() => alert('توثيق عبر تطبيق أبشر غير متاح حالياً')}>توثيق عبر تطبيق أبشر</button>
//                     <button onClick={() => alert('توثيق عبر تطبيق نفاذ غير متاح حالياً')}>توثيق عبر تطبيق نفاذ</button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default DocumentYourContract;

import React, { useState } from 'react';
import './DocumentYourContract.css'; // Import the CSS file
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DocumentYourContract = () => {
    const { t, i18n } = useTranslation("global");

    const location = useLocation();
    const { name, phone, total, reference_number, email } = location.state || {};

    const [error, setError] = useState('');
    const [amount, setAmount] = useState(total || 0); // Use total as the initial amount

    // Log the state to debug
    console.log('DocumentYourContract State:', { name, phone, amount, reference_number, email });

    const handleSendSMS = () => {
        const phoneNumber = '+966599489990';
        const message = encodeURIComponent(
            'Please ensure all required fields are completed accurately before submitting your contract documentation. This step is crucial for the verification process. Thank you for your cooperation. If you need assistance, please contact the Lahalak support team.'
        );
        window.location.href = `sms:${phoneNumber}?body=${message}`;
    };

    const handleSendWhatsApp = () => {
        const phoneNumber = '+966599489990';
        const message = encodeURIComponent(
            'Please ensure all required fields are completed accurately before submitting your contract documentation. This step is crucial for the verification process. Thank you for your cooperation. If you need assistance, please contact the Lahalak support team.'
        );
        window.location.href = `https://wa.me/${phoneNumber}?text=${message}`;
    };
    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };
    return (                 

        <div className="document-your-contract" dir={getDirection(i18n.language)}>
            <div className="content">
                <h1>{t("DocumentYourContract.txt1")}</h1> 
                <p> {t("DocumentYourContract.txt2")}</p> 
                {error && <p style={{ color: 'red' }}>{error}</p>}
                {amount !== null && <p>{t("DocumentYourContract.txt3")}{amount.toFixed(2)} {t("Purchase.Pur6")}</p>}
                <div className="buttonsa">
                    <button className='blueNewTkhile'   onClick={handleSendSMS}>{t("DocumentYourContract.txt4")}</button>
                    <button className='blueNewTkhile'  onClick={handleSendWhatsApp}>{t("DocumentYourContract.txt5")}</button>



                    <Link to="/Payment" state={{
                        name: name,
                        phone: phone,
                        amount: amount,
                        email: email
                    }}>
                        <div className="text-center">
                            <button  className='blueNewTkhile'  type="button" >
                                Pay
                            </button>
                        </div>
                    </Link>
                    <button  className='blueNewTkhile'   onClick={() => alert(t("DocumentYourContract.txt8"))}>{t("DocumentYourContract.txt6")}</button>
                    <button className='blueNewTkhile'   onClick={() => alert(t("DocumentYourContract.txt9"))}>{t("DocumentYourContract.txt7")}</button>
                </div>
            </div>
        </div>
    );
};

export default DocumentYourContract;
