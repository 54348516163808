import React, { useState, useEffect } from 'react';
import './Slideshow.css';
import icon5Img from '../components/images/icon5Img.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Slideshow = () => {
  const { t, i18n } = useTranslation("global"); // Initialize the translation hook
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };

  const cardsData = [
    {
      img: 'https://lahalak.sa/favicon.ico', // Using the URL directly
      title: t("Slideshow.title1"),
      text: t("Slideshow.text1"),
    },
    {
      img: 'https://lahalak.sa/favicon.ico', // Using the URL directly
      title: t("Slideshow.title2"),
      text: t("Slideshow.text2"),
    },
    {
      img: 'https://lahalak.sa/favicon.ico', // Using the URL directly
      title: t("Slideshow.title3"),
      text: t("Slideshow.text3"),
    },
    {
      img: 'https://lahalak.sa/favicon.ico', // Using the URL directly
      title: t("Slideshow.title4"),
      text: t("Slideshow.text4"),
    },
  ];

  return (
    <div className='why' style={{ direction: getDirection() }}>
      <br />
      <br />
      <br />
      <p className='warda'>{t("Slideshow.title")}</p>
      <div className="cardss-container" >
        {cardsData.map((card, index) => (
          <div key={index} className="cards">
            <div className="cards-text">
              <div className="cards-img">
                <img src={card.img} alt="icon" /> {/* Use the URL directly in the src */}
              </div>
              <br />
              <div className="cards-title">{card.title}</div>
              <div className="cards-body">{card.text}</div>
            </div>
          </div>
        ))}
      </div>
      <br />
      <div>
        <p className="subTitleCont" style={{ direction: getDirection() }}>{t("Slideshow.titleQuestionD")}</p>
        <div className="ArselLanaTalabak" dir={getDirection()}>
          <p className='titleQuestionD'> </p> 
          <Link to="/SendMessage" state={{ MessagTitle: "طلب معين" }}>
            <button> 
              {t("Slideshow.txtQuestionD1")}
            </button>
          </Link>
        </div> 
      </div>
      <br />
    </div>
  );
};

export default Slideshow;
