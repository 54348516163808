import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { doc, setDoc } from 'firebase/firestore';
import { getFirestore, collection } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import styled from 'styled-components';
import "./UserInfoPage.css";
import { useTranslation } from 'react-i18next';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

const UserDataTable = styled.table`
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  text-align: left;
`;

const TableHeader = styled.th`
  background-color: #f4f4f4;
  color: #333;
  font-weight: bold;
  padding: 8px;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }

  &:hover {
    background-color: #f1f1f1;
  }
`;

const TableCell = styled.td`
  padding: 8px;
  border-top: 1px solid #ddd;
`;

const UserInfoPage = () => {
  const { t, i18n } = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();

  const { cartItems = [], total = 0, service = "", birthDescription = "", roomType = "", id, specialConditions = [],
    bookingDetails = [], includedServices = [], serviceDetails = [] } = location.state || {};
  const [nationalityError, setNationalityError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState(''); // State to hold the error message
  const [convertedPhone, setConvertedPhone] = useState(''); // Converted phone for backend use

  const [nationality, setNationality] = useState('');
  const [currentLocation, setCurrentLocation] = useState(null);
  const [MapUrl, setMapUrl] = useState(null);
  const [isEditing, setIsEditing] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [documentId, setDocumentId] = useState("");
  const [referenceNumber, setReferenceNumber] = useState('');
  const [pregnancyMonth, setPregnancyMonth] = useState('');

  const firestore = getFirestore(app);
  const usersCollection = collection(firestore, 'orders');
  const [reference_number, setreference_number] = useState('');
  const validateNationality = () => {
    if (nationality === "") {
      setNationalityError(t("UserInfoPage.nationalityRequired"));
      return false;
    }
    setNationalityError('');
    return true;
  };
  const validatePhone = (enteredPhone) => {
    const phonePattern = /^05\d{8}$/;
    if (!phonePattern.test(enteredPhone)) {
      setPhoneError(t("UserInfoPage.invalidPhone"));
      return false;
    }
    setPhoneError('');
    return true;
  };


  const handlePhoneChange = (e) => {
    const enteredPhone = e.target.value;
    setPhone(enteredPhone);
    if (validatePhone(enteredPhone)) {
      setPhoneError('');
      const converted = `966${enteredPhone.slice(1)}`; // Convert to "966XXXXXXXXX" format
      setConvertedPhone(converted);
    } else {
      setPhoneError(t("UserInfoPage.invalidPhone"));
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const determinePregnancyMonth = (serviceValue) => {
    switch (serviceValue) {
      case '': return 'X';
      case '5': return 1;
      case '-1': return 2;
      case '-2': return 3;
      case '-5': return 4;
      case '-7': return 5;
      case '-9': return 6;
      case '-11': return 7;
      case '-15': return 8;
      case '-17': return 9;
      default: return 'Unknown';
    }
  };

  useEffect(() => {
    setPregnancyMonth(determinePregnancyMonth(service));
  }, [service]);

  const UploadData = async () => {
    setIsLoading(true);  // Set loading to true when the upload starts
    if (phone.length !== 10 || !phone.startsWith("05")) {
      setPhoneError(t("UserInfoPage.invalidPhone")); // Display error if validation fails
      setIsLoading(false);  // Stop loading since there's an error
      return;
    } else {
      setPhoneError(''); // Clear error if the validation passes
    }
    setUploadError('');
    if (!userName) {
      setUploadError(t("UserInfoPage.userNameRequired"));
      return;
    }
    if (!validateNationality()) {
      return;
    }

    if (!email) {
      setUploadError(t("UserInfoPage.emailRequired"));
      return;
    }
    if (!phone) {
      setUploadError(t("UserInfoPage.phoneRequired"));
      return;
    }

    const generatereference_number = () => {
      let refNumber = '';
      for (let i = 0; i < 16; i++) {
        refNumber += Math.floor(Math.random() * 10).toString();
      }
      return refNumber;
    };

    const parsedTotal = typeof total === 'string' ? Number(total) : total;
    const servicePrice = parsedTotal * 0.75;
    const contractPrice = parsedTotal * 0.25;
    const vatServicePrice = nationality === "Saudi" ? 0 : servicePrice * 0.15;
    const vatContractPrice = contractPrice * 0.15;
    const totalWithVat = parsedTotal + vatServicePrice + vatContractPrice;

    const refNumber = generatereference_number();
    const currentDate = new Date();
    const optionsGregorian = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedGregorianDate = new Intl.DateTimeFormat(i18n.language === "ar" ? "en-US" : "en-US", optionsGregorian).format(currentDate);

    const preparedData = {
      document_id: currentDate.toISOString().replace(/[\W_]+/g, "-"),
      date: formattedGregorianDate,
      map_url: MapUrl || '',
      email: email || '',
      name: userName || '',
      nationality: nationality || '',
      phone: convertedPhone || '', // Use the converted phone number for backend
      pregnancy_month: determinePregnancyMonth(service),
      reference_number: refNumber,
      room_type: roomType,
      total: totalWithVat || 0,
      cartItems: cartItems.map(item => ({
        id: item.id || '',
        Title: item.Title || '',
        type: item.type || '',
        price: item.Price || 0,
        quantity: item.naturalBirth || 0,
      })),
    };

    try {
      const docRef = doc(firestore, "orders", preparedData.document_id);
      await setDoc(docRef, preparedData, { merge: true });
      console.log("Data uploaded to Firestore:", preparedData);

      const response = await fetch('https://lahalak.sa/backend/uploaded.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          document_id: preparedData.document_id,
          date: preparedData.date,
          map_url: preparedData.map_url,
          service: birthDescription,
          email: preparedData.email,
          name: preparedData.name,
          nationality: preparedData.nationality,
          phone: preparedData.phone, // Send converted phone number to backend
          pregnancy_month: preparedData.pregnancy_month,
          reference_number: preparedData.reference_number,
          room_type: preparedData.room_type,
          total: preparedData.total,
          item_title: cartItems.map(item => item.Title).join(", "),
          item_id: cartItems.map(item => item.id).join(", "),
          item_price: cartItems.map(item => item.Price).join(", "),
          item_quantity: cartItems.map(item => item.naturalBirth).join(", "),
          item_type: cartItems.map(item => item.type).join(", ")
        }).toString(),
      });

      const result = await response.text();
      console.log('Data uploaded to phpMyAdmin:', result);

      setDocumentId(preparedData.document_id);
      setreference_number(refNumber);
      setIsConfirmed(true);
    } catch (error) {
      console.error('Error uploading data:', error);
      setUploadError(t("UserInfoPage.error"));
    } finally {
      setIsEditing(false);
      setIsLoading(false);  // Set loading to false when upload completes

    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCurrentLocation({ latitude, longitude });
        setMapUrl(`https://www.google.com/maps?q=${latitude},${longitude}`);
      },
      (error) => console.error('Error getting location:', error.message)
    );
  }, []);

  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };

  const currentDate = new Date();
  const optionsGregorian = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
  const formattedGregorianDate = new Intl.DateTimeFormat("en-US", optionsGregorian).format(currentDate);

  const formattedDate = `${formattedGregorianDate}`;
  const handleButtonClick = () => {
    navigate("/Pdfuser", {
      state: {
        id: id,
        name: userName,
        phone: phone,
        total: total,
        pregnancyMonth: pregnancyMonth,
        cartItems: cartItems.map(item => ({
          id: item.id || '',
          Title: item.Title || '',
          type: item.type || '',
          image1: item.image1 || '',
          quantity: 1
        })),
        reference_number: reference_number,
        nationality: nationality,
        Service: birthDescription,
        date: formattedDate,
        roomType: roomType,
        hospital: cartItems.length > 0 ? cartItems[0].Title : '',
        email: email,
        specialConditions: specialConditions,
        bookingDetails: bookingDetails,
        includedServices: includedServices,
        serviceDetails: serviceDetails || [],
      }
    });
  };

  return (
    <div className='backgroundwhiteASS' style={{ direction: getDirection() }}>
      <div>
        <div className="bannersol" style={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '120px',
          position: 'relative',
          textAlign: 'center'
        }}>
          <div className="bannerText">
            <div className='romel'>
            </div>
          </div>
        </div>

        

        <div className="cont">
          <h1>{t("UserInfoPage.title")}</h1>
          <fieldset>
            <label htmlFor="userName">{t("UserInfoPage.userName")}</label>
            <input
              disabled={!isEditing}
              type="text"
              id="userName"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              required
            />
            <label htmlFor="nationality">{t("UserInfoPage.nationality")}</label>
            <select
              disabled={!isEditing}
              id="nationality"
              value={nationality}
              onChange={(e) => setNationality(e.target.value)}
              required
            >
              <option value="">{t("UserInfoPage.nationality")}</option>
              <option value="Saudi">{t("UserInfoPage.value1")}</option>
              <option value="Non-Saudi">{t("UserInfoPage.value2")}</option>
            </select>

            {nationalityError && (
              <div className="error-message">
                <span className="error-icon">⚠️</span>
                <p className="error-text">{nationalityError}</p>
              </div>
            )}

            <label htmlFor="email">{t("UserInfoPage.email")}</label>
            <input
              disabled={!isEditing}
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="phone">{t("UserInfoPage.phone")}</label>
            <input
              type="tel"
              id="phone"
              value={phone}
              onChange={handlePhoneChange}
              inputMode="numeric" // Ensures numeric keyboard on mobile
              placeholder="05XXXXXXXX" // Guide user with the expected format
              required
            />
            {phoneError && (
              <div className="error-message">
                <span className="error-icon">⚠️</span>
                <p className="error-text">{phoneError}</p>
              </div>
            )}

          </fieldset>
          <UserDataTable>
          <thead>
            <tr style={{ direction: getDirection() }}>
              <TableHeader>{t("UserInfoPage.serviceName")}</TableHeader>
              <TableHeader>{t("UserInfoPage.pricePerItem")}</TableHeader>
              <TableHeader>{t("UserInfoPage.totalForItem")}</TableHeader>
              <TableHeader>{t("UserInfoPage.quantity")}</TableHeader>
              <TableHeader>{t("UserInfoPage.service")}</TableHeader>
            </tr>
          </thead>
          <tbody>
            {cartItems.map(item => (
              <TableRow key={item.id}>
                <TableCell>{item.Title}</TableCell>
                <TableCell>
                  {
                    Number.isNaN(Number((item.Price - (item.Price * service / 100))) + (Number(item.pro) || 0))
                      ? (total - ((25 * total) / 100)).toFixed(2)
                      : (Number((item.Price - (item.Price * service / 100))) + (Number(item.pro) || 0)
                        - ((25 * (Number((item.Price - (item.Price * service / 100))) + (Number(item.pro) || 0))) / 100)).toFixed(2)
                  } {t("Purchase.Pur6")}
                </TableCell>
                <TableCell>
                  {
                    Number.isNaN((Number((item.Price - (item.Price * service / 100))) + (Number(item.pro) || 0)))
                      ? ((total * 25) / 100).toFixed(2)
                      : (((Number((Number((item.Price - (item.Price * service / 100))) + (Number(item.pro) || 0)))) * 25) / 100).toFixed(2)
                  } {t("Purchase.Pur6")}
                </TableCell>
                <TableCell>{total.toFixed(2)} {t("Purchase.Pur6")}</TableCell>
                <TableCell>{birthDescription}</TableCell>
              </TableRow>
            ))}

          </tbody>
        </UserDataTable>
          <div className="form-actions">
            {uploadError && (
              <div className="error-message">
                <span className="error-icon">⚠️</span>
                <p className="error-text">{uploadError}</p>
              </div>
            )}
            <div className="text-center">
              <button
                type="button"
                onClick={UploadData}
                className="button-yellow blueNewTkhile"
                disabled={!isEditing || isLoading} // Disable the button while loading
              >
                {isLoading ? (
                  <span className="custom-spinner">
                    <i className="fa fa-spinner fa-spin custom-spinner-icon"></i>
                    <span className="custom-spinner-text">{t("UserInfoPage.loadingText")}</span>
                  </span>
                ) : (
                  <span>{t("UserInfoPage.text")}</span>
                )}
              </button>
            </div>

            {isConfirmed && (
              <div className="text-center">
                <button type="button" className='TkhileAride' onClick={handleButtonClick}>
                  {t("UserInfoPage.text1")}
                </button>
              </div>
            )}
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default UserInfoPage;





// import React, { useState, useEffect } from 'react';
// import { useAuthContext } from "../context/AuthContext";
// import { useLocation, Link, useNavigate } from "react-router-dom";
// import { doc, setDoc } from 'firebase/firestore';
// import { getFirestore, collection } from 'firebase/firestore';
// import { initializeApp } from 'firebase/app';
// import styled from 'styled-components';
// import { useTranslation } from 'react-i18next';
// import "./UserInfoPage.css";
// import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// };

// const app = initializeApp(firebaseConfig);
// const UserDataTable = styled.table`
//   width: 100%;
//   margin-top: 20px;
//   border-collapse: collapse;
//   text-align: left;
// `;

// const TableHeader = styled.th`
//   background-color: #f4f4f4;
//   color: #333;
//   font-weight: bold;
//   padding: 8px;
// `;

// const TableRow = styled.tr`
//   &:nth-child(even) {
//     background-color: #f9f9f9;
//   }

//   &:hover {
//     background-color: #f1f1f1;
//   }
// `;

// const TableCell = styled.td`
//   padding: 8px;
//   border-top: 1px solid #ddd;
// `;

// const UserInfoPage = () => {
// const { user } = useAuthContext();
//   const { t, i18n } = useTranslation("global");
//   const navigate = useNavigate();

//   const location = useLocation();
//   const { cartItems = [], total = 0, service = "", birthDescription = "", roomType = "", id, specialConditions = [],
//     bookingDetails = [], includedServices = [], serviceDetails = [],
//   } = location.state || {};
//   const [initialDate] = useState(new Date());
//   const [userName, setUserName] = useState('');
//   const [email, setemail] = useState('');
//   const [phone, setPhone] = useState('');
//   const [nationality, setNationality] = useState('');
//   const [currentLocation, setCurrentLocation] = useState(null);
//   const [MapUrl, setMapUrl] = useState(null);
//   const [isEditing, setIsEditing] = useState(true);
//   const [isConfirmed, setIsConfirmed] = useState(false);
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//   });
//   const [nameError, setNameError] = useState(false);
//   const [emailError, setemailError] = useState(false);
//   const [phoneError, setphoneError] = useState(false);
//   const [error, setError] = useState(null);
//   const [IsButtonDisabled, setIsButtonDisabled] = useState(false);
//   const [pregnancyMonth, setPregnancyMonth] = useState('');

//   const firestore = getFirestore(app);
//   const usersCollection = collection(firestore, 'orders');
//   const handleConfirmButtonClick = () => {
//     setIsEditing(false);
//     UploadData();
//     setIsButtonDisabled(true);
//   };

//   const [documentId, setDocumentId] = useState("");
//   const [uploadError, setUploadError] = useState('');

//   const [reference_number, setreference_number] = useState('');

//   const determinePregnancyMonth = (serviceValue) => {
//     switch (serviceValue) {
//       case '':
//         return 'X';
//       case '5':
//         return 1;
//       case '-1':
//         return 2;
//       case '-2':
//         return 3;
//       case '-5':
//         return 4;
//       case '-7':
//         return 5;
//       case '-9':
//         return 6;
//       case '-11':
//         return 7;
//       case '-15':
//         return 8;
//       case '-17':
//         return 9;
//       default:
//         return 'Unknown';
//     }
//   };

//   useEffect(() => {
//     setPregnancyMonth(determinePregnancyMonth(service));
//   }, [service]);
//   const UploadData = async () => {
//     setNameError(false);
//     setError(null);
//     setUploadError('');
//     if (!userName) {
//       setNameError(true);
//       setUploadError(t("UserInfoPage.userNameRequired"));
//       return;
//     }
//     if (!email) {
//       setemailError(true);
//       setUploadError(t("UserInfoPage.emailRequired"));
//       return;
//     }
//     if (!phone) {
//       setphoneError(true);
//       setUploadError(t("UserInfoPage.phoneRequired"));
//       return;
//     }
//     const generatereference_number = () => {
//       let refNumber = '';
//       for (let i = 0; i < 16; i++) {
//         refNumber += Math.floor(Math.random() * 10).toString();
//       }
//       return refNumber;
//     };
//     const parsedTotal = typeof total === 'string' ? Number(total) : total;
//     const servicePrice = parsedTotal * 0.75;
//     const contractPrice = parsedTotal * 0.25;
//     const vatServicePrice = nationality === "Saudi" ? 0 : servicePrice * 0.15;
//     const vatContractPrice = contractPrice * 0.15;
//     const totalWithVat = parsedTotal + vatServicePrice + vatContractPrice;

//     const refNumber = generatereference_number();
//     const currentDate = new Date();
//     const optionsGregorian = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
//     const formattedGregorianDate = new Intl.DateTimeFormat(i18n.language === "ar" ? "en-US" : "en-US", optionsGregorian).format(currentDate);

//     const preparedData = {
//       document_id: currentDate.toISOString().replace(/[\W_]+/g, "-"),
//       date: formattedGregorianDate,
//       map_url: MapUrl || '',
//       email: email || '',
//       name: userName || '',
//       nationality: nationality || '',
//       phone: phone || '',
//       pregnancy_month: determinePregnancyMonth(service),
//       reference_number: refNumber,
//       room_type: roomType,
//       total: totalWithVat || 0,
//       cartItems: cartItems.map(item => ({
//         id: item.id || '',
//         Title: item.Title || '',
//         type: item.type || '',
//         price: item.Price || 0,
//         quantity: item.naturalBirth || 0,
//       })),
//     };

//     try {
//       const docRef = doc(firestore, "orders", preparedData.document_id);
//       await setDoc(docRef, preparedData, { merge: true });
//       console.log("Data uploaded to Firestore:", preparedData);

//       const response = await fetch('https://lahalak.sa/backend/uploaded.php', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/x-www-form-urlencoded',
//         },
//         body: new URLSearchParams({
//           document_id: preparedData.document_id,
//           date: preparedData.date,
//           map_url: preparedData.map_url,
//           service: preparedData.service,
//           email: preparedData.email,
//           name: preparedData.name,
//           nationality: preparedData.nationality,
//           phone: preparedData.phone,
//           pregnancy_month: preparedData.pregnancy_month,
//           reference_number: preparedData.reference_number,
//           room_type: preparedData.room_type,
//           total: preparedData.total,
//           item_title: cartItems.map(item => item.Title).join(", "),
//           item_id: cartItems.map(item => item.id).join(", "),
//           item_price: cartItems.map(item => item.Price).join(", "),
//           item_quantity: cartItems.map(item => item.naturalBirth).join(", "),
//           item_type: cartItems.map(item => item.type).join(", ")
//         }).toString(),
//       });

//       const result = await response.text();
//       console.log('Data uploaded to phpMyAdmin:', result);

//       setDocumentId(preparedData.document_id);
// setreference_number(refNumber);
//       setIsConfirmed(true);
//     } catch (error) {
//       console.error('Error uploading data:', error);
//       alert(t("UserInfoPage.error"));
//     } finally {
//       setIsEditing(false);
//     }
//   };
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         setCurrentLocation({ latitude, longitude });
//         setMapUrl(`https://www.google.com/maps?q=${latitude},${longitude}`);
//       },
//       (error) => {
//         console.error('Error getting location:', error.message);
//       }
//     );
//   }, []);



// const getDirection = () => {
//   return i18n.language === "ar" ? "rtl" : "ltr";
// };

// const currentDate = new Date();
// const optionsGregorian = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
// const optionsHijri = { year: 'numeric', month: 'numeric', day: 'numeric', calendar: 'islamic', numberingSystem: 'arab' };

// const formattedGregorianDate = new Intl.DateTimeFormat("en-US", optionsGregorian).format(currentDate);

// const formattedDate = `${formattedGregorianDate}`;

//   const handleButtonClick = () => {
//     navigate("/Pdfuser", {
//       state: {
//         id: id,
//         name: userName,
//         phone: phone,
//         total: total,
//         pregnancyMonth: pregnancyMonth,
//         cartItems: cartItems.map(item => ({
//           id: item.id || '',
//           Title: item.Title || '',
//           type: item.type || '',
//           image1: item.image1 || '',
//           quantity: 1
//         })),
//         reference_number: reference_number,
//         nationality: nationality,
//         Service: birthDescription,
//         date: formattedDate,
//         roomType: roomType,
//         hospital: cartItems.length > 0 ? cartItems[0].Title : '',
//         email: email,
//         specialConditions: specialConditions,
//         bookingDetails: bookingDetails,
//         includedServices: includedServices,
//         serviceDetails: serviceDetails || [],

//       }
//     });
//   };

//   return (
//     <div className='backgroundwhiteASS' style={{ direction: getDirection() }}>
//       <div>
//         <div className="bannersol" style={{
//           backgroundSize: 'cover',
//           backgroundPosition: 'center',
//           height: '120px',
//           position: 'relative',
//           textAlign: 'center'
//         }}>
//           <div className="bannerText">
//             <br />
//             <div className='romel'>
//               <div className="tabra1">
//                 <div className="tabra2">
//                   <div className="tabra6"></div>
//                   <p className="tabra4">{t("Details.hi1")}</p>
//                 </div>
//                 <div className="tabra5">......</div>
//                 <div className="tabra2">
//                   <div className="tabra6"></div>
//                   <p className="tabra4">{t("Details.hi2")}</p>
//                 </div>
//                 <div className="tabra5">......</div>
//                 <div className="tabra2">
//                   <div className="tabra3"></div>
//                   <p className="tabra4">{t("Details.hi3")}</p>
//                 </div>
//                 <div className="tabra5">......</div>
//                 <div className="tabra2">
//                   <div className="tabra3"></div>
//                   <p className="tabra4">{t("Details.hi4")}</p>
//                 </div>
//                 <div className="tabra5">......</div>
//                 <div className="tabra2">
//                   <div className="tabra3"></div>
//                   <p className="tabra4">{t("Details.hi5")}</p>
//                 </div>
//                 <div className="tabra5">......</div>
//                 <div className="tabra2">
//                   <div className="tabra3"></div>
//                   <p className="tabra4">{t("Details.hi6")}</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <UserDataTable>
//           <thead>
//             <tr style={{ direction: getDirection() }}>
//               <TableHeader>{t("UserInfoPage.serviceName")}</TableHeader>
//               <TableHeader>{t("UserInfoPage.pricePerItem")}</TableHeader>
//               <TableHeader>{t("UserInfoPage.totalForItem")}</TableHeader>
//               <TableHeader>{t("UserInfoPage.quantity")}</TableHeader>
//               <TableHeader>{t("UserInfoPage.service")}</TableHeader>
//             </tr>
//           </thead>
//           <tbody>
//             {cartItems.map(item => (
//               <TableRow key={item.id}>
//                 <TableCell>{item.Title}</TableCell>
//                 <TableCell>
//                   {
//                     Number.isNaN(Number((item.Price - (item.Price * service / 100))) + (Number(item.pro) || 0))
//                       ? (total - ((25 * total) / 100)).toFixed(2)
//                       : (Number((item.Price - (item.Price * service / 100))) + (Number(item.pro) || 0)
//                         - ((25 * (Number((item.Price - (item.Price * service / 100))) + (Number(item.pro) || 0))) / 100)).toFixed(2)
//                   } {t("Purchase.Pur6")}
//                 </TableCell>
//                 <TableCell>
//                   {
//                     Number.isNaN((Number((item.Price - (item.Price * service / 100))) + (Number(item.pro) || 0)))
//                       ? ((total * 25) / 100).toFixed(2)
//                       : (((Number((Number((item.Price - (item.Price * service / 100))) + (Number(item.pro) || 0)))) * 25) / 100).toFixed(2)
//                   } {t("Purchase.Pur6")}
//                 </TableCell>
//                 <TableCell>{total.toFixed(2)} {t("Purchase.Pur6")}</TableCell>
//                 <TableCell>{birthDescription}</TableCell>
//               </TableRow>
//             ))}

//           </tbody>
//         </UserDataTable>
//       </div>
//       <div style={{ padding: 20 }} >
//         <div style={{ padding: 20 }} className="cont">
//           <h1>{formattedDate}</h1>
//           <h1>{t("UserInfoPage.title")}</h1>
//           <fieldset>
//             <legend>{t("UserInfoPage.title_1")}</legend>
//             <label htmlFor="userName">{t("UserInfoPage.userName")}</label>
//             <input
//               disabled={!isEditing}
//               type="text"
//               id="userName"
//               value={userName}
//               onChange={(e) => {
//                 console.log("Input value:", e.target.value);
//                 setUserName(e.target.value);
//               }}
//               required
//             />
//             <label htmlFor="nationality">{t("UserInfoPage.nationality")}</label>
//             <select
//               disabled={!isEditing}
//               id="nationality"
//               value={nationality}
//               onChange={(e) => setNationality(e.target.value)}
//               required
//             >
//               <option value="">{t("UserInfoPage.nationality")}</option>
//               <option value="Saudi">{t("UserInfoPage.value1")}</option>
//               <option value="Non-Saudi">{t("UserInfoPage.value2")}</option>
//             </select>
//             <label htmlFor="email">{t("UserInfoPage.email")}</label>
//             <input
//               disabled={!isEditing}
//               type="email"
//               id="email"
//               value={email}
//               onChange={(e) => setemail(e.target.value)}
//               required
//             />
//             <label htmlFor="phone">{t("UserInfoPage.phone")}</label>
//             <input
//               disabled={!isEditing}
//               type="tel"
//               id="phone"
//               value={phone}
//               onChange={(e) => {
//                 setPhone(e.target.value);
//               }}
//               required
//             />
//           </fieldset>
//           <div className="form-actions">
//             {uploadError && (
//               <div className="error-message">
//                 <span className="error-icon">⚠️</span>
//                 <p className="error-text">{uploadError}</p>
//               </div>
//             )}
//             <div className="text-center">
//               <button
//                 type="button"
//                 onClick={UploadData}
//                 className="button-yellow blueNewTkhile"
//                 disabled={!isEditing}>
//                 {t("UserInfoPage.text")}
//               </button>
//             </div>
//             {isConfirmed && (
//               <div className="text-center">
//                 <button type="button" className='TkhileAride' onClick={handleButtonClick}>
//                   {t("UserInfoPage.text1")}
//                 </button>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>

//   );
// };

// export default UserInfoPage;







// import React, { useState, useEffect } from 'react';
// import { useAuthContext } from "../context/AuthContext";
// import { useLocation, Link, useNavigate } from "react-router-dom";
// import { doc, setDoc } from 'firebase/firestore';
// import { getFirestore, collection } from 'firebase/firestore';
// import { initializeApp } from 'firebase/app';
// import styled from 'styled-components';
// import { useTranslation } from 'react-i18next';
// import "./UserInfoPage.css";
// import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// };

// const app = initializeApp(firebaseConfig);
// const UserDataTable = styled.table`
//   width: 100%;
//   margin-top: 20px;
//   border-collapse: collapse;
//   text-align: left;
// `;

// const TableHeader = styled.th`
//   background-color: #f4f4f4;
//   color: #333;
//   font-weight: bold;
//   padding: 8px;
// `;

// const TableRow = styled.tr`
//   &:nth-child(even) {
//     background-color: #f9f9f9;
//   }

//   &:hover {
//     background-color: #f1f1f1;
//   }
// `;

// const TableCell = styled.td`
//   padding: 8px;
//   border-top: 1px solid #ddd;
// `;

// const UserInfoPage = () => {
//   const { user } = useAuthContext();
//   const { t, i18n } = useTranslation("global");
//   const navigate = useNavigate();

//   const location = useLocation();
//   const { cartItems = [], total = 0, service = "", birthDescription = "", roomType = "", id, specialConditions = [],
//     bookingDetails = [], includedServices = [], serviceDetails = [],
//   } = location.state || {};
//   const [initialDate] = useState(new Date());
//   const [userName, setUserName] = useState('');
//   const [email, setemail] = useState('');
//   const [phone, setPhone] = useState('');
//   // const [gender, setGender] = useState('');
//   // const [dob, setDob] = useState('');
//   const [nationality, setNationality] = useState('');
//   const [currentLocation, setCurrentLocation] = useState(null);
//   const [MapUrl, setMapUrl] = useState(null);
//   const [isEditing, setIsEditing] = useState(true);
//   const [isConfirmed, setIsConfirmed] = useState(false);
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     // gender: "",
//     // birthdate: "",
//   });
//   const [nameError, setNameError] = useState(false);
//   const [emailError, setemailError] = useState(false);
//   // const [genderError, setgenderError] = useState(false);
//   const [phoneError, setphoneError] = useState(false);
//   // const [selectedOption, setSelectedOption] = useState('');
//   const [error, setError] = useState(null);
//   const [IsButtonDisabled, setIsButtonDisabled] = useState(false);
//   const [pregnancyMonth, setPregnancyMonth] = useState('');

//   const firestore = getFirestore(app);
//   const usersCollection = collection(firestore, 'orders');
//   const handleConfirmButtonClick = () => {
//     setIsEditing(false);
//     UploadData();
//     setIsButtonDisabled(true);
//   };

//   const [documentId, setDocumentId] = useState("");
//   const [uploadError, setUploadError] = useState('');
//   // const [option1Checked, setOption1Checked] = useState(false);
//   // const [option2Checked, setOption2Checked] = useState(false);

//   // const handleCheckboxChange = (option) => {
//   //   setSelectedOption(option);
//   //   setError(null);
//   // };

//   const [reference_number, setreference_number] = useState('');

//   const determinePregnancyMonth = (serviceValue) => {
//     switch (serviceValue) {
//       case '':
//         return 'X';
//       case '5':
//         return 1;
//       case '-1':
//         return 2;
//       case '-2':
//         return 3;
//       case '-5':
//         return 4;
//       case '-7':
//         return 5;
//       case '-9':
//         return 6;
//       case '-11':
//         return 7;
//       case '-15':
//         return 8;
//       case '-17':
//         return 9;
//       default:
//         return 'Unknown';
//     }
//   };

//   useEffect(() => {
//     setPregnancyMonth(determinePregnancyMonth(service));
//   }, [service]);





//   const UploadData = async () => {
//     setNameError(false);
//     // setgenderError(false);
//     setError(null);
//     setUploadError('');
//     if (!userName) {
//       setNameError(true);
//       setUploadError(t("UserInfoPage.userNameRequired"));
//       return;
//     }
//     if (!email) {
//       setemailError(true);
//       setUploadError(t("UserInfoPage.emailRequired"));
//       return;
//     }
//     if (!phone) {
//       setphoneError(true);
//       setUploadError(t("UserInfoPage.phoneRequired"));
//       return;
//     }
//     // if (!gender) {
//     //   setgenderError(true);
//     //   setUploadError(t("UserInfoPage.genderRequired"));
//     //   return;
//     // }

//     // if (!selectedOption) {
//     //   setError(t("UserInfoPage.Checked"));
//     //   setUploadError(t("UserInfoPage.Checked"));
//     //   return;
//     // }
//     const generatereference_number = () => {
//       let refNumber = '';
//       for (let i = 0; i < 16; i++) {
//         refNumber += Math.floor(Math.random() * 10).toString();
//       }
//       return refNumber;
//     };

//     const parsedTotal = typeof total === 'string' ? Number(total) : total;
//     const servicePrice = parsedTotal * 0.75;
//     const contractPrice = parsedTotal * 0.25;
//     const vatServicePrice = nationality === "Saudi" ? 0 : servicePrice * 0.15;
//     const vatContractPrice = contractPrice * 0.15;
//     const totalWithVat = parsedTotal + vatServicePrice + vatContractPrice;

//     const refNumber = generatereference_number();
//     const currentDate = new Date();
//     const optionsGregorian = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
//     const formattedGregorianDate = new Intl.DateTimeFormat(i18n.language === "ar" ? "en-US" : "en-US", optionsGregorian).format(currentDate);

//     const preparedData = {
//       document_id: currentDate.toISOString().replace(/[\W_]+/g, "-"),
//       date: formattedGregorianDate, // Gregorian date to be uploaded
//       map_url: MapUrl || '',
//       // service: birthDescription || '',
//       email: email || '',
//       // gender: gender || '',
//       name: userName || '',
//       nationality: nationality || '',
//       phone: phone || '',
//       pregnancy_month: determinePregnancyMonth(service),
//       reference_number: refNumber,
//       room_type: roomType,
//       total: totalWithVat || 0,
//       cartItems: cartItems.map(item => ({
//         id: item.id || '',
//         Title: item.Title || '',
//         type: item.type || '',
//         price: item.Price || 0,
//         quantity: item.naturalBirth || 0,
//       })),
//     };

//     try {
//       const docRef = doc(firestore, "orders", preparedData.document_id);
//       await setDoc(docRef, preparedData, { merge: true });
//       console.log("Data uploaded to Firestore:", preparedData);

//       const response = await fetch('https://lahalak.sa/backend/uploaded.php', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/x-www-form-urlencoded',
//         },
//         body: new URLSearchParams({
//           document_id: preparedData.document_id,
//           date: preparedData.date, // Sending only the Gregorian date
//           map_url: preparedData.map_url,
//           service: preparedData.service,
//           email: preparedData.email,
//           // gender: preparedData.gender,
//           name: preparedData.name,
//           nationality: preparedData.nationality,
//           phone: preparedData.phone,
// pregnancy_month: preparedData.pregnancy_month,
// reference_number: preparedData.reference_number,
//           room_type: preparedData.room_type,
//           total: preparedData.total,
//           item_title: cartItems.map(item => item.Title).join(", "),
//           item_id: cartItems.map(item => item.id).join(", "),
//           item_price: cartItems.map(item => item.Price).join(", "),
//           item_quantity: cartItems.map(item => item.naturalBirth).join(", "),
//           item_type: cartItems.map(item => item.type).join(", ")
//         }).toString(),
//       });

//       const result = await response.text();
//       console.log('Data uploaded to phpMyAdmin:', result);

//       setDocumentId(preparedData.document_id);
//       setreference_number(refNumber);
//       setIsConfirmed(true);
//     } catch (error) {
//       console.error('Error uploading data:', error);
//       alert(t("UserInfoPage.error"));
//     } finally {
//       setIsEditing(false);
//     }
//   };

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         setCurrentLocation({ latitude, longitude });
//         setMapUrl(`https://www.google.com/maps?q=${latitude},${longitude}`);
//       },
//       (error) => {
//         console.error('Error getting location:', error.message);
//       }
//     );
//   }, []);



// const getDirection = () => {
//   return i18n.language === "ar" ? "rtl" : "ltr";
// };

//   const currentDate = new Date();
//   const optionsGregorian = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
//   const optionsHijri = { year: 'numeric', month: 'numeric', day: 'numeric', calendar: 'islamic', numberingSystem: 'arab' };

//   const formattedGregorianDate = new Intl.DateTimeFormat("en-US", optionsGregorian).format(currentDate);
//   const formattedHijriDate = new Intl.DateTimeFormat("ar-SA", optionsHijri).format(currentDate);

//   const formattedDate = `${formattedGregorianDate}`;

//   const handleButtonClick = () => {
//     navigate("/Pdfuser", {
//       state: {
//         id: id,
//         name: userName,
//         phone: phone,
//         total: total,
//         pregnancyMonth: pregnancyMonth,
//         cartItems: cartItems.map(item => ({
//           id: item.id || '',
//           Title: item.Title || '',
//           type: item.type || '',
//           image1: item.image1 || '',
//           quantity: 1
//         })),
//         reference_number: reference_number,
//         nationality: nationality,
//         Service: birthDescription,
//         date: formattedDate,
//         roomType: roomType,
//         hospital: cartItems.length > 0 ? cartItems[0].Title : '',
//         email: email,
//         specialConditions: specialConditions,
//         bookingDetails: bookingDetails,
//         includedServices: includedServices,
//         serviceDetails: serviceDetails || [],

//       }
//     });
//   };

//   return (
// <div className='backgroundwhiteASS' style={{ direction: getDirection() }}>
//       <div>
//         <div className="bannersol" style={{
//           backgroundSize: 'cover',
//           backgroundPosition: 'center',
//           height: '120px',
//           position: 'relative',
//           textAlign: 'center'
//         }}>
//           <div className="bannerText">
//             <br />
//             <div className='romel'>
//               <div className="tabra1">
//                 <div className="tabra2">
//                   <div className="tabra6"></div>
//                   <p className="tabra4">{t("Details.hi1")}</p>
//                 </div>
//                 <div className="tabra5">......</div>
//                 <div className="tabra2">
//                   <div className="tabra6"></div>
//                   <p className="tabra4">{t("Details.hi2")}</p>
//                 </div>
//                 <div className="tabra5">......</div>
//                 <div className="tabra2">
//                   <div className="tabra3"></div>
//                   <p className="tabra4">{t("Details.hi3")}</p>
//                 </div>
//                 <div className="tabra5">......</div>
//                 <div className="tabra2">
//                   <div className="tabra3"></div>
//                   <p className="tabra4">{t("Details.hi4")}</p>
//                 </div>
//                 <div className="tabra5">......</div>
//                 <div className="tabra2">
//                   <div className="tabra3"></div>
//                   <p className="tabra4">{t("Details.hi5")}</p>
//                 </div>
//                 <div className="tabra5">......</div>
//                 <div className="tabra2">
//                   <div className="tabra3"></div>
//                   <p className="tabra4">{t("Details.hi6")}</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <UserDataTable>
//           <thead>
//             <tr style={{ direction: getDirection() }}>
//               <TableHeader>{t("UserInfoPage.serviceName")}</TableHeader>
//               <TableHeader>{t("UserInfoPage.pricePerItem")}</TableHeader>
//               <TableHeader>{t("UserInfoPage.totalForItem")}</TableHeader>
//               <TableHeader>{t("UserInfoPage.quantity")}</TableHeader>
//               <TableHeader>{t("UserInfoPage.service")}</TableHeader>
//             </tr>
//           </thead>
//           <tbody>
//             {cartItems.map(item => (
//               <TableRow key={item.id}>
//                 <TableCell>{item.Title}</TableCell>
//                 <TableCell>
//                   {
//                     Number.isNaN(Number((item.Price - (item.Price * service / 100))) + (Number(item.pro) || 0))
//                       ? (total - ((25 * total) / 100)).toFixed(2)
//                       : (Number((item.Price - (item.Price * service / 100))) + (Number(item.pro) || 0)
//                         - ((25 * (Number((item.Price - (item.Price * service / 100))) + (Number(item.pro) || 0))) / 100)).toFixed(2)
//                   } {t("Purchase.Pur6")}
//                 </TableCell>
//                 <TableCell>
//                   {
//                     Number.isNaN((Number((item.Price - (item.Price * service / 100))) + (Number(item.pro) || 0)))
//                       ? ((total * 25) / 100).toFixed(2)
//                       : (((Number((Number((item.Price - (item.Price * service / 100))) + (Number(item.pro) || 0)))) * 25) / 100).toFixed(2)
//                   } {t("Purchase.Pur6")}
//                 </TableCell>
//                 <TableCell>{total.toFixed(2)} {t("Purchase.Pur6")}</TableCell>
//                 <TableCell>{birthDescription}</TableCell>
//               </TableRow>
//             ))}

//           </tbody>
//         </UserDataTable>
//       </div>
//       <div style={{ padding: 20 }} >
//         <div style={{ padding: 20 }} className="cont">
//           <h1>{formattedDate}</h1>
//           <h1>{t("UserInfoPage.title")}</h1>
//           <fieldset>
//             <legend>{t("UserInfoPage.title_1")}</legend>
//             <label htmlFor="userName">{t("UserInfoPage.userName")}</label>
//             <input
//               disabled={!isEditing}
//               type="text"
//               id="userName"
//               value={userName}
//               onChange={(e) => {
//                 console.log("Input value:", e.target.value);
//                 setUserName(e.target.value);
//               }}
//               required
//             />
//             {/* <label htmlFor="dob">{t("UserInfoPage.dob")}</label>
//             <input
//               disabled={!isEditing}
//               type="date"
//               id="dob"
//               value={dob}
//               onChange={(e) => setDob(e.target.value)}
//               required
//             /> */}
//             {/* <label htmlFor="gender">{t("UserInfoPage.gender")}</label> */}
//             {/* <select
//               disabled={!isEditing}
//               id="gender"
//               value={gender}
//               onChange={(e) => setGender(e.target.value)}
//               required
//             >
//               <option value="">{t("UserInfoPage.gender")}</option>
//               <option value="male">{t("UserInfoPage.male")}</option>
//               <option value="female">{t("UserInfoPage.female")}</option>
//               <option value="other">{t("UserInfoPage.other")}</option>
//             </select> */}
//             <label htmlFor="nationality">{t("UserInfoPage.nationality")}</label>
//             <select
//               disabled={!isEditing}
//               id="nationality"
//               value={nationality}
//               onChange={(e) => setNationality(e.target.value)}
//               required
//             >
//               <option value="">{t("UserInfoPage.nationality")}</option>
//               <option value="Saudi">{t("UserInfoPage.value1")}</option>
//               <option value="Non-Saudi">{t("UserInfoPage.value2")}</option>
//             </select>
//             <label htmlFor="email">{t("UserInfoPage.email")}</label>
//             <input
//               disabled={!isEditing}
//               type="email"
//               id="email"
//               value={email}
//               onChange={(e) => setemail(e.target.value)}
//               required
//             />
//             <label htmlFor="phone">{t("UserInfoPage.phone")}</label>
//             <input
//               disabled={!isEditing}
//               type="tel"
//               id="phone"
//               value={phone}
//               onChange={(e) => {
//                 setPhone(e.target.value);
//               }}
//               required

//             />

//             {/* <div className="check1">
//               <div className="check2">
//                 <input
//                   type="checkbox"
//                   id="option1"
//                   name="option"
//                   value="option1"
//                   checked={selectedOption === 'option1'}
//                   onChange={() => handleCheckboxChange('option1')}
//                 />
//                 <label htmlFor="option1">{t("UserInfoPage.Checked_1")}</label>
//               </div>
//               <div className="check2">
//                 <input
//                   type="checkbox"
//                   id="option2"
//                   name="option"
//                   value="option2"
//                   checked={selectedOption === 'option2'}
//                   onChange={() => handleCheckboxChange('option2')}
//                 />
//                 <label htmlFor="option2">{t("UserInfoPage.Checked_2")}</label>
//               </div>
//             </div> */}
//           </fieldset>
//           <div className="form-actions">
//             {uploadError && (
//               <div className="error-message">
//                 <span className="error-icon">⚠️</span>
//                 <p className="error-text">{uploadError}</p>
//               </div>
//             )}
//             <div className="text-center">
//               <button
//                 type="button"
//                 onClick={UploadData}
//                 className="button-yellow blueNewTkhile"
//                 disabled={!isEditing}>
//                 {t("UserInfoPage.text")}
//               </button>
//             </div>
//             {isConfirmed && (
//               <div className="text-center">
//                 <button type="button" className='TkhileAride' onClick={handleButtonClick}>
//                   {t("UserInfoPage.text1")}
//                 </button>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>

//   );
// };

// export default UserInfoPage;
