
import React from 'react';
import { useEffect, useState } from 'react';
import { initializeApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer.js';
import Navbare from './components/Navbare';
import SignUp from './components/SignUp';
import Home from './components/Home';
import Login from './components/Login';
import Solutions from './components/Solutions';
import Automation from './components/Automation';
import Safety from './components/Safety';
import Security from './components/Security';
import Buying_guide from './components/Buying_guide';
import Connectivity from './components/Connectivity';
import Support from './components/Support';
import Contact_Us from './components/Contact_Us';
import LatestNews from './components/LatestNews';
import Cabel from './components/Cabel';
import Phone from './components/Phone';
import Controlunit from './components/Controlunit';
import Article from './components/Article';
import Ourclient from './components/Ourclient';
import Details from './Details';
import SignUpPage from './SignUpPage';
import Purchase from './Purchase';
import LogForgetpassword from './components/LogForgetpassword';
import UserInfoPage from './components/UserInfoPage'; 
import DetailsAdd from './components/DetailsAdd';
import DetailsAddOnServices from './components/DetailsAddOnServices';
import DocumentYourContract from './components/DocumentYourContract';
import HospitalServiceAdd from './components/HospitalServiceAdd.js';
import Service_provider from './components/Service_provider.js';
import Pdfuser from './components/Pdfuser.js';
import Service_Details from './components/Service_Details.js';
import ServiceAdd_Details from './components/ServiceAdd_Details.js';
import MYcontracts from './components/MYcontracts.js';
import PersonalInfoForm from './components/PersonalInfoForm.js';
import SendMessage from './components/SendMessage.js';
import Payment from './components/Payment';
import Privacy from './components/Privacy';
import Contract_provisions from './components/Contract_provisions';
import Terms_and_conditions from './components/Terms_and_conditions';
import Privacy_policy from './components/Privacy_policy.js';
import My_appointments from './components/My_appointments.js';
import My_contracts from './components/My_contracts.js';
import Establishment_Services_assistance from './components/Establishment_Services_assistance.js';
import Est_Services from './components/Est_Services.js';
import Job_applications from './components/Job_applications.js';
import Calendar from './components/Calendar.js';
import { useAuthContext } from './context/AuthContext';
import splashGif from './assets/lahaandlak.gif';
import '@fortawesome/fontawesome-free/css/all.css';
import { AuthProvider } from "./context/AuthContext";
import VisitorLogger from './components/VisitorLogger';
import Verify from './components/Verify.js';
import VerifyOTP from './components/VerifyOTP.js';

const call = 'https://api.whatsapp.com/send?phone=+966599489990&text=Hello!';

function AppContent() {
  const { user } = useAuthContext();

  return (
    <BrowserRouter>
      <Navbare />
      <VisitorLogger /> 

      {/* <div className="fixed-button">
        <button>
          <a className="text-white" href={call}>
            <MdSupportAgent size={30} />
          </a>
        </button>
      </div> */}
      <Routes>
        <Route path="/" element={<Purchase />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/contact_us" element={<Contact_Us />} />
        <Route path="/SendMessage" element={<SendMessage />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/automation" element={<Automation />} />
        <Route path="/safety" element={<Safety />} />
        <Route path="/connectivity" element={<Connectivity />} />
        <Route path="/support" element={<Support />} />
        <Route path="/Latestnews" element={<LatestNews />} />
        <Route path="/cabel" element={<Cabel />} />
        <Route path="/phone" element={<Phone />} />
        <Route path="/controlunit" element={<Controlunit />} />
        <Route path="/article" element={<Article />} />
        <Route path="/details" element={ <Details />  } />
        {/* <Route path="/details" element={user ? <Details /> : <Navigate to="/signup" />} /> */}
        <Route path="/ourclient" element={<Ourclient />} />
        <Route path="/forget" element={<LogForgetpassword />} />
        <Route path="/user-info" element={<UserInfoPage />} />
        <Route path="/Pdfuser" element={<Pdfuser />} />
        <Route path="/Service_providers" element={<Security />} />
        <Route path="/Buying_guide" element={<Buying_guide />} />
        <Route path="/DetailsAdd" element={<DetailsAdd />} />
        <Route path="/HospitalServiceAdd" element={ <HospitalServiceAdd /> } />
        <Route path="/Service_Details" element={<Service_Details />} />
        <Route path="/Document_Your_Contract" element={<DocumentYourContract />} />
        <Route path="/contracts" element={<DocumentYourContract />} />
        <Route path="/home" element={<Home />} />
        <Route path="/My_appointments" element={<My_appointments />} />
        <Route path="/My_contracts" element={<My_contracts />} />
        <Route path="/MYcontracts" element={<MYcontracts />} />
        <Route path="/PersonalInfoForm" element={<PersonalInfoForm />} />
        <Route path="/Payment" element={<Payment />} />
        <Route path="/Contract_provisions" element={<Contract_provisions />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/ServiceAdd_Details" element={ <ServiceAdd_Details /> } />
        <Route path="/verify" element={<Verify />} />
        <Route path="/Terms_and_conditions" element={<Terms_and_conditions />} />
        <Route path="/privacy_policy" element={<Privacy_policy />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/user-info" element={<UserInfoPage />} />
        <Route path="/DetailsAddOnServices" element={<DetailsAddOnServices />} />
        <Route path="/Service_provider" element={<Service_provider />} />
        <Route path="/Job_applications" element={<Job_applications />} />
        <Route path="/verify-otp" element={<VerifyOTP />} />

        <Route path="/Establishment_Services_assistance" element={<Establishment_Services_assistance />} />
        <Route path="/Est_Services" element={<Est_Services />} />
        <Route path="/ServiceAdd_Details" element={<ServiceAdd_Details /> } />
        <Route path="*" element={<div><h1>404 Page not found.</h1></div>} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

function App() {
  useEffect(() => {
    if (!getApps().length) {
      const app = initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
      });
      const analytics = getAnalytics(app);
    }
  }, []);
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

export default App;
