import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './AddOnServices.css';

const AddOnServices = ({ addToCart }) => {
  const { t, i18n } = useTranslation("global");
  const [Addonservices, setAddonservices] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 

  useEffect(() => { 
    const loadData = async () => {
      try {
        setIsLoading(true);
        const data = i18n.language === "ar" 
          ? await import('./translation/Ar/Add_on_services.json')
          : await import('./translation/En/Add_on_services.json');
        console.log("Loaded data:", data.default);
        setAddonservices(data.default);
      } catch (error) {
        console.error("Error loading additional services data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    loadData();
  }, [i18n.language]);

  return (
    <div>
      <br />
      <h1 className="TitlContw">{t("Purchase.Pur4_2")}</h1> 
      <div className='speed1'>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          Array.isArray(Addonservices) && Addonservices.map(service => (
            <div className="speed2" key={service.id}>
              <Link to={`/DetailsAddOnServices?id=${service.id}`}>
  <img className="speed3" src={service.image} alt="Service Visual Representation" />
  <h5 className="speed4">{service.title}</h5>
  <p className="speed5">{service.summary}</p>
</Link>

            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default AddOnServices;