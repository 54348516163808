// import React from 'react';
// import { useLocation } from 'react-router-dom';
// import './Service_Details.css'; // Ensure this CSS file is properly linked for styling

// const Service_Details = () => {
//     const location = useLocation();
// Safely destructuring id, price, and optionName from state with a fallback
// const { id, price, optionName, hospitalName, hospitalLocation } = location.state || {};

//     return (
//         <div className="service-details-container">
//             <h1>Service Details</h1>
//             {id ? (
//                 <div className="service-info">
//                     <h2>Service ID: {id}</h2>
//                     <p>Option Name: {optionName}</p> {/* Displaying the option name */}
//                     <p>hospital Name: {hospitalName}</p> {/* Displaying the option name */}
//                     <p>  hospital Location:{hospitalLocation}  </p>
//                     <p>Price: {price}</p> {/* Displaying the price */}
//                 </div>
//             ) : (
//                 <p>No service ID provided.</p>
//             )}
//         </div>
//     );
// };

// export default Service_Details;








import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import icon2Img from '../components/images/se.png';
import icon3Img from '../components/images/sg.png';
import './Service_Details.css';

const Service_Details = () => {
    const location = useLocation();
    const { id, price, optionName, hospitalName, hospitalLocation, image, bookingDetails, sections, useContent1 } = location.state || {};

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { t, i18n } = useTranslation("global");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const conditionsSectionTitle = t("Details.specialConditions");
    const includedServicesSectionTitle = t("Details.includedServices");
    const conditionsSection = sections?.find(section => section.title === conditionsSectionTitle);
    const includedServicesSection = sections?.find(section => section.title === includedServicesSectionTitle);

    const renderContent = (content) => {
        if (Array.isArray(content)) {
            return content.map((item, index) => (
                <div className='titlecollapseeB' key={index}>
                    <p>{item.text || item}</p>
                </div>
            ));
        } else {
            return (
                <div className='titlecollapseeB'>
                    <img src={icon2Img} alt="Icon" style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                    <p>{content}</p>
                </div>
            );
        }
    };

    return (
        <div className="backgroundwhiteASS" dir={getDirection(i18n.language)}>
            <br />
            <br />
            <br />
            <p className='TitlContwo' style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {t("Details.sub")}
            </p>
            <br />
            <div>
                <br />
                <p className='titlecollapsee'>{t("Details.sub1")}</p>
                <div className='articleContent'>
                    <div className='det_gr'>
                        <div className='gellule'>
                            <p className='titlenormalcolapsText'>{t("Details.sub2")}</p>
                            <p>{hospitalName}</p>
                        </div>
                        <div className='gellule'>
                            <p className='titlenormalcolapsText'>{t("Details.sub3")}</p>
                            <p>{hospitalLocation}</p>
                        </div>
                        {optionName && (
                            <div className='gellule'>
                                <p className='titlenormalcolapsText'>{t("Details.sub4")}</p>
                                <p>{optionName}</p>
                            </div>
                        )}
                        <div className='gellule'>
                            <p className='titlenormalcolapsText'>{t("Details.sub5")}</p>
                            <p>{price}</p>
                        </div>
                    </div>
                </div>
                <div className='Bluediv'>
                    <p className='titlecollapseeWak'>{t("Details.includedServices")}</p>
                </div>
                <br />
                <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                    <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                        <li className="iconTrue" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <div style={{ flex: 1 }}>
                                {includedServicesSection && renderContent(useContent1 ? includedServicesSection.content1 : includedServicesSection.content)}
                            </div>
                        </li>
                    </ul>
                </div>

                <div className='Bluediv'>
                    <p className='titlecollapseeWak'>{t("Details.addedValues")}</p>
                </div>
                <br />
                <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                    {bookingDetails && bookingDetails.map((detail, index) => (
                        <div key={index}>
                            {Object.entries(detail).map(([key, value]) => (
                                <ul key={key}>
                                    <li className="iconTrue">
                                        <img src={icon2Img} alt="Icon" style={{ width: '15px', height: '15px' }} />
                                        <p>{value}</p>
                                    </li>
                                </ul>
                            ))}
                        </div>
                    ))}
                </div>
                <div className='Bluediv'>
                    <p className='titlecollapseeWak'>{t("Details.specialConditions")}</p>
                </div>
                <br />
                <br />
                <div className={`${i18n.language === 'ar' ? 'rtl' : 'ltr'} titlecollapseeB`} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                    <ul style={{ padding: 0 }}>
                        {conditionsSection && conditionsSection.content.map((condition, index) => (
                            <li className="circle-list-item" key={index}>{condition.text || condition}</li>
                        ))}
                    </ul>
                </div>
                <div className='Bluediv'>
                    <p className='titlecollapseeWak'>{t("Details.generalConditions")}</p>
                </div>
                <br />
                <br />
                <div className={`${i18n.language === 'ar' ? 'rtl' : 'ltr'} titlecollapseeB`} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                    <ul style={{ padding: 0 }}>
                        <li className="circle-list-item">{t("Details.sub32")}</li>
                        <li className="circle-list-item">{t("Details.sub33")}</li>
                        <li className="circle-list-item">{t("Details.sub34")}</li>
                        <li className="circle-list-item">{t("Details.sub35")}</li>
                        <li className="circle-list-item">{t("Details.sub36")}</li>
                        <li className="circle-list-item">{t("Details.sub37")}</li>
                    </ul>
                </div>

                <br />
                <div className="text-center">
                    <Link to="/user-info" state={{
                        cartItems: [{
                            type: optionName, quantity: 1, image1: image, Title: hospitalName,
                        }],
                        total: Number(price), // Ensure total is a number
                        id: id,
                        hospitalName: hospitalName,
                        hospitalLocation: hospitalLocation,
                        birthDescription: optionName,
                        specialConditions: conditionsSection ? conditionsSection.content : [],
                        bookingDetails: bookingDetails || [],
                        includedServices: includedServicesSection ? includedServicesSection.content : [],
                    }}>
                        <button className='purchase-button'>{t("Purchase.Pur16")}</button>
                        <br />
                        <br />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Service_Details;
