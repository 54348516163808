import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './PhoneList.css';
import Pill from "./Pill";

const PhoneList = ({ addToCart }) => {
  const { t, i18n } = useTranslation("global");
  const [additionalServices, setAdditionalServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Added loading state

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);
        const dataUrl = i18n.language === "ar"
          ? 'https://lahalak.sa/backend/json_files/translation/Ar/additionalServices.json'
          : 'https://lahalak.sa/backend/json_files/translation/En/additionalServices.json';

        const response = await fetch(dataUrl);
        const data = await response.json();
        setAdditionalServices(data);
      } catch (error) {
        console.error("Error loading additional services data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    loadData();
  }, [i18n.language]);

  const handleAddToCart = (service) => {
    const itemToAdd = {
      ...service,
      type: 'additional_service',
    };
    addToCart(itemToAdd);
  };

  return (
    <div>
      <br />
      <h1 className="TitlContw">{t("Purchase.Pur4")}</h1>
      <div className='speed1'>
        {isLoading ? (
          <div>
            <Pill />
          </div>) : (
          additionalServices.map(service => (
            <div className="speed2" key={service.id}>
              <Link to={`/DetailsAdd?id=${service.id}`}>
                <img className="speed3" src={service.image} alt="Service Visual Representation" />
                <h5 className="speed4">{service.title}</h5>
                <p className="speed5">{service.summary}</p>
              </Link>

            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default PhoneList;
