/* global TabbyCard */

import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import './Payment.css'; // Import the CSS file
import { useTranslation } from 'react-i18next';
import { useAuthContext } from "../context/AuthContext";

const Payment = () => {
    const { user } = useAuthContext();

    const { t, i18n } = useTranslation("global");
    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };
    const location = useLocation(); 
    const { name, phone, amount, reference_number, email } = location.state || {};
 
    // Log the state to debug
    console.log('Payment Component State:', { reference_number });
    console.log('emailemailemailemail:', { email });

    const [error, setError] = useState('');
    const [paymentOption, setPaymentOption] = useState('NORMAL'); 

    useEffect(() => {
        if (!name || !phone || !amount || !email) {
            setError(t("Payment.missingInfo"));
        }
    }, [name, phone, amount, email, t]);

    const handlePaymentOptionChange = (e) => {
        setPaymentOption(e.target.value);
    };












    const handleSubmit = async (e) => {
        e.preventDefault();
    
        console.log("Amount:", amount);
        console.log("Payment Option:", paymentOption);
        console.log("Email:", email);
        console.log("Phone Number:", phone);
    
        if (!amount) {
            setError(t("Payment.missingAmount"));
            return;
        }
        if (!paymentOption) {
            setError(t("Payment.missingPaymentOption"));
            return;
        }
        if (!email) {
            setError(t("Payment.missingEmail"));
            return;
        }
        if (!phone) {
            setError(t("Payment.missingPhone"));
            return;
        }
    
        const formData = new FormData();
        formData.append('amount', amount);
        formData.append('payment_option', paymentOption); 
        formData.append('email', email);
        formData.append('phone_number', phone);
    
        try {
            const response = await fetch('processPayment.php', {
                method: 'POST',
                body: formData
            });
    
            const result = await response.text();
            document.open();
            document.write(result);
            document.close();
        } catch (error) {
            setError(t("Payment.errorProcessing"));
        }
    };
    




    useEffect(() => {
        if (paymentOption === 'TABBY') {
            const script = document.createElement('script');
            script.src = 'https://checkout.tabby.ai/tabby-card.js';
            script.async = true;
            script.onload = () => {
                new TabbyCard({
                    selector: '#tabbyCard',
                    currency: 'SAR',
                    lang: i18n.language,
                    price: amount,
                    size: 'narrow',
                    theme: 'black',
                    header: false
                });
            };
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
        }
    }, [amount, i18n.language, paymentOption]);
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="tabby1-container" style={{ direction: getDirection() }}>
            <h1 className="paymentfor">{t("Payment.paymentForm")}</h1>
            {error && <p className="tabby1-error-message">{error}</p>}
            <div className="card-wrapper">
                <div className="card card-background">
                    <form onSubmit={handleSubmit} className="tabby1-form">
                        <div className="tabby2-group">
                            <label>{t("Payment.amountSAR")}</label> 
                            <p className="tabby2-value">{(amount).toFixed(2)}</p>
                            
                        </div>
                        <div className="tabby3-group">
                            <label htmlFor="payment_option">{t("Payment.selectPaymentOption")}:</label>
                            <select
                                id="payment_option"
                                name="payment_option"
                                value={paymentOption}
                                onChange={handlePaymentOptionChange}
                                required
                                className="tabby3-select"
                            >
                                <option value="NORMAL">{t("Payment.normalPayment")}</option>
                                <option value="CUSTOMER_SERVICE">{t("Payment.customerServicePayment")}</option>
                            </select>
                        </div>
                        <div className="tabby4-group">
                            <label>{t("Payment.email")}:</label>
                            <p className="tabby4-value">{email}</p>
                        </div>
                        <div className="tabby5-group">
                            <label>{t("Payment.phoneNumber")}:</label>
                            <p className="tabby5-value">{phone}</p>
                        </div>
                        <div className="tabby6-group">
                            {paymentOption === 'CUSTOMER_SERVICE' ? (
                              <Link
                              style={{ color: '#FFFFFF' }}
                              to="/calendar"
                              state={{
                                  reference_number: reference_number,
                                  name: name,
                                  phone: phone,
                                  amount: amount,
                                  email: email
                              }}
                          >
                              <div className="text-center">
                                  <button className='blueNewTkhile'>
                                      {t("Payment.schedulePaymen")}
                                  </button>
                              </div>
                          </Link>
                          
                            ) : (
                                <button type="submit" className="tabby6-button">
                                    {t("Payment.payNow")}
                                </button>
                            )}
                        </div>
                    </form>
                </div>
                {paymentOption === 'TABBY' && (
                    <div className="card card-white">
                        <div className="tabby-branding">
                            <img src="https://cdn.tabby.ai/assets/tabby-badge.png" alt="Tabby Logo" className="tabby-logo" />
                            <p className="tabby-message">{t("Payment.payInInstallments")}</p>
                        </div>
                        <div id="tabbyCard"></div>
                    </div>
                )}
                {paymentOption === 'NORMAL' && (
                    <div className="card card-white">
                        <p>{t("Payment.normalPaymentDescription")}</p>
                    </div>
                )}
                {paymentOption === 'CUSTOMER_SERVICE' && (
                    <div className="card card-white">
                        <p>{t("Payment.customerServicePaymentDescription")}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Payment;
