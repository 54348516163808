import React, { useEffect, useState } from "react";
import "./Buying_guide.css";
import { useTranslation } from 'react-i18next';
import back from '../components/images/erty.png';
import { useSpring, animated } from 'react-spring';
import { Link, useNavigate } from "react-router-dom";
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';

const Buying_guide = () => {
    const { t, i18n } = useTranslation("global");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    const [openArticle, setOpenArticle] = useState(null);
    const toggleSection = (section) => {
        setOpenSection(current => current === section ? null : section);
    };
    const toggleArticle = (article) => {
        setOpenArticle(current => current === article ? null : article);
    };
    const [openSection, setOpenSection] = useState(null);

    const [isOpen, setIsOpen] = useState({
        problem: false,
        solution: false,
        service: false,
        ourSpecification: false,
        ourSpecificationend: false,
        contact: false,
        FAQs: false,
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={getDirection() + " backgroundwhiteASS"} style={{ direction: getDirection() }}>
            <div className="bannersol" style={{ backgroundImage: `url(${back})` }}>
            </div>
            <div className="bahite" dir={getDirection(i18n.language)}>
                <br />
                <div>
                    <p className="GoldCont"> {t("Navbar.dalilshira")}</p>
                </div>
                <br />
                <div>
                    <p className="normalcolapsTextCaption">{t("Buying_guide.tit1")}</p>

                    <button className="button_mada" onClick={() => toggleSection('problem')} aria-expanded={openSection === 'problem'}>
                        <animated.span style={{ transform: openSection === 'problem' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                            {openSection === 'problem' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </animated.span>
                        <p className="textCont">{t("gide.social")}</p>
                    </button>
                    {openSection === 'problem' && (
                        <animated.div style={{
                            backgroundColor: '#F4F6FA',
                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                        }}>
                            <div id="problem" className={`ContactU ${openSection === 'problem' ? 'open' : 'closed'}`}>

                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('subProb1')} aria-expanded={openArticle === 'subProb1'}>
                                        <animated.span style={{ transform: openArticle === 'subProb1' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'subProb1' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.subProb1")}</p>
                                    </button>
                                    {openArticle === 'subProb1' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="normalcolapsText">{t("Buying_guide.tit2")}</p>
                                        </animated.div>
                                    )}
                                    {openArticle !== 'subProb1' && <div className="line"></div>}
                                </div>

                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('subFAQs10')} aria-expanded={openArticle === 'subFAQs10'}>
                                        <animated.span style={{ transform: openArticle === 'subFAQs10' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'subFAQs10' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.subProb2")}</p>
                                    </button>
                                    {openArticle === 'subFAQs10' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tit3")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tit4")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tit5")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tit6")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tit7")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tit8")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tit9")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tit10")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tit11")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tit12")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tit13")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tit14")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tit15")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tit16")}</p>
                                        </animated.div>
                                    )}
                                    {openArticle !== 'subFAQs10' && <div className="line"></div>}
                                </div>
                                {/* <p className="whiteH">hiiii</p> */}
                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('subProb3')} aria-expanded={openArticle === 'subProb3'}>
                                        <animated.span style={{ transform: openArticle === 'subProb3' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'subProb3' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.subProb3")}</p>
                                    </button>
                                    {openArticle === 'subProb3' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="normalcolapsText">{t("Buying_guide.did1")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.did2")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.did3")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.did4")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.did5")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.did6")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.did7")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.did8")}</p>
                                        </animated.div>
                                    )}
                                    {openArticle !== 'subProb3' && <div className="line"></div>}
                                </div>

                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('subProb4')} aria-expanded={openArticle === 'subProb4'}>
                                        <animated.span style={{ transform: openArticle === 'subProb4' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'subProb4' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.subProb4")}</p>
                                    </button>
                                    {openArticle === 'subProb4' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.pip1")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.pip2")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.pip3")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.pip4")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.pip5")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.pip6")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.pip7")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.pip8")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.pip9")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.pip10")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.pip11")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.pip12")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.pip13")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.pip14")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.pip15")}</p>
                                        </animated.div>
                                    )}
                                    {openArticle !== 'subProb4' && <div className="line"></div>}
                                </div>
                            </div>
                        </animated.div>
                    )}
                    {!openSection && <div className="line"></div>}
                </div>





                <div>
                    <button className="button_mada" onClick={() => toggleSection('solution')} aria-expanded={openSection === 'solution'}>
                        <animated.span style={{ transform: openSection === 'solution' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                            {openSection === 'solution' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </animated.span>
                        <p className="textCont">{t("Buying_guide.Awareness")}</p>
                    </button>
                    {openSection === 'solution' && (
                        <animated.div style={{
                            backgroundColor: '#F4F6FA',
                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                        }}>
                            <div id="solution" className={`ContactU ${openSection === 'solution' ? 'open' : 'closed'}`}>
                                <p className="normalcolapsText">{t("Buying_guide.AwarenessSub")}</p>

                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('section1')} aria-expanded={openArticle === 'section1'}>
                                        <animated.span style={{ transform: openArticle === 'section1' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'section1' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.pib1")}</p>
                                    </button>
                                    {openArticle === 'section1' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="normalcolapsText">{t("Buying_guide.pib2")}</p>
                                            <p className="normalcolapsText">{t("Buying_guide.pib3")}</p>
                                            {/* <div className="line"></div> */}
                                        </animated.div>
                                    )}
                                    {openArticle !== 'section1' && <div className="line"></div>}
                                </div>

                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('section2')} aria-expanded={openArticle === 'section2'}>
                                        <animated.span style={{ transform: openArticle === 'section2' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'section2' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.pib4")}</p>
                                    </button>
                                    {openArticle === 'section2' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="normalcolapsText">{t("Buying_guide.pib5")}</p>
                                            <p className="normalcolapsText">{t("Buying_guide.pib6")}</p>
                                            {/* <div className="line"></div> */}
                                        </animated.div>
                                    )}
                                    {openArticle !== 'section2' && <div className="line"></div>}
                                </div>

                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('section3')} aria-expanded={openArticle === 'section3'}>
                                        <animated.span style={{ transform: openArticle === 'section3' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'section3' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.pib7")}</p>
                                    </button>
                                    {openArticle === 'section3' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="normalcolapsText">{t("Buying_guide.pib8")}</p>
                                            <p className="normalcolapsText">{t("Buying_guide.pib9")}</p>
                                            {/* <div className="line"></div> */}
                                        </animated.div>
                                    )}
                                    {openArticle !== 'section3' && <div className="line"></div>}
                                </div>

                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('section4')} aria-expanded={openArticle === 'section4'}>
                                        <animated.span style={{ transform: openArticle === 'section4' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'section4' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.pib10")}</p>
                                    </button>
                                    {openArticle === 'section4' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="normalcolapsText">{t("Buying_guide.pib11")}</p>
                                            <p className="normalcolapsText">{t("Buying_guide.pib12")}</p>
                                            {/* <div className="line"></div> */}
                                        </animated.div>
                                    )}
                                    {openArticle !== 'section4' && <div className="line"></div>}
                                </div>

                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('section5')} aria-expanded={openArticle === 'section5'}>
                                        <animated.span style={{ transform: openArticle === 'section5' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'section5' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.pib13")}</p>
                                    </button>
                                    {openArticle === 'section5' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="normalcolapsText">{t("Buying_guide.pib14")}</p>
                                            {/* <div className="line"></div> */}
                                        </animated.div>
                                    )}
                                    {openArticle !== 'section5' && <div className="line"></div>}
                                </div>

                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('section6')} aria-expanded={openArticle === 'section6'}>
                                        <animated.span style={{ transform: openArticle === 'section6' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'section6' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.pib15")}</p>
                                    </button>
                                    {openArticle === 'section6' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="normalcolapsText">{t("Buying_guide.pib16")}</p>
                                            {/* <div className="line"></div> */}
                                        </animated.div>
                                    )}
                                    {openArticle !== 'section6' && <div className="line"></div>}
                                </div>

                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('section7')} aria-expanded={openArticle === 'section7'}>
                                        <animated.span style={{ transform: openArticle === 'section7' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'section7' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.pib17")}</p>
                                    </button>
                                    {openArticle === 'section7' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="normalcolapsText">{t("Buying_guide.pib18")}</p>
                                            <p className="normalcolapsText">{t("Buying_guide.pib19")}</p>
                                            {/* <div className="line"></div> */}
                                        </animated.div>
                                    )}
                                    {openArticle !== 'section7' && <div className="line"></div>}
                                </div>

                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('section8')} aria-expanded={openArticle === 'section8'}>
                                        <animated.span style={{ transform: openArticle === 'section8' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'section8' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.pib20")}</p>
                                    </button>
                                    {openArticle === 'section8' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="normalcolapsText">{t("Buying_guide.pib21")}</p>
                                            {/* <div className="line"></div> */}
                                        </animated.div>
                                    )}
                                    {openArticle !== 'section8' && <div className="line"></div>}
                                </div>

                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('section9')} aria-expanded={openArticle === 'section9'}>
                                        <animated.span style={{ transform: openArticle === 'section9' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'section9' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.pib22")}</p>
                                    </button>
                                    {openArticle === 'section9' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="normalcolapsText">{t("Buying_guide.pib23")}</p>
                                            <p className="normalcolapsText">{t("Buying_guide.pib24")}</p>
                                            {/* <div className="line"></div> */}
                                        </animated.div>
                                    )}
                                    {openArticle !== 'section9' && <div className="line"></div>}
                                </div>

                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('section10')} aria-expanded={openArticle === 'section10'}>
                                        <animated.span style={{ transform: openArticle === 'section10' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'section10' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.pib25")}</p>
                                    </button>
                                    {openArticle === 'section10' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="normalcolapsText">{t("Buying_guide.pib26")}</p>
                                            <p className="normalcolapsText">{t("Buying_guide.pib27")}</p>
                                            {/* <div className="line"></div> */}
                                        </animated.div>
                                    )}
                                    {openArticle !== 'section10' && <div className="line"></div>}
                                </div>

                                <div className="backgroundsnowwhite">
                                    <div className="columncontact">
                                        <p className="titlenormalcolapsText">{t("Buying_guide.pib28")}</p>
                                        <p className="titlenormalcolapsTextULX">{t("Buying_guide.pib29")}</p>
                                        <p className="titlenormalcolapsTextULX">{t("Buying_guide.pib30")}</p>
                                        <p className="titlenormalcolapsTextULX">{t("Buying_guide.pib31")}</p>
                                        <p className="titlenormalcolapsTextULX">{t("Buying_guide.pib32")}</p>
                                        <p className="titlenormalcolapsTextULX">{t("Buying_guide.pib33")}</p>
                                        <p className="titlenormalcolapsTextULX">{t("Buying_guide.pib34")}</p>
                                        <div className="snow">
                                            <div className="widthbutton">
                                                <Link to="/SendMessage" state={{ MessagTitle: "مراسله" }}>
                                                    <button className="sendbtnOne sendbtn" >
                                                        {t("security.send")}
                                                    </button>
                                                </Link>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </animated.div>
                    )}
                    {!openSection && <div className="line"></div>}
                </div>


                <div>
                    <button className="button_mada" onClick={() => toggleSection('service')} aria-expanded={openSection === 'service'}>
                        <animated.span style={{ transform: openSection === 'service' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                            {openSection === 'service' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </animated.span>
                        <p className="textCont">{t("Buying_guide.tst")}</p>
                    </button>

                    {openSection === 'service' && (
                        <animated.div style={{
                            backgroundColor: '#F4F6FA',
                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                        }}>
                            <div id="service" className={`ContactU ${openSection === 'service' ? 'open' : 'closed'}`}>
                                <p className="normalcolapsText">{t("Buying_guide.tst1")}</p>
                                {/* <div className="line"></div> */}

                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('subservice1')} aria-expanded={openArticle === 'subservice1'}>
                                        <animated.span style={{ transform: openArticle === 'subservice1' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'subservice1' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.tst2")}</p>
                                    </button>
                                    {openArticle === 'subservice1' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="normalcolapsText">{t("Buying_guide.tst7")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tst8")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tst9")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tst10")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tst11")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tst12")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tst13")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tst14")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tst15")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tst16")}</p>
                                            {/* <div className="line"></div> */}
                                        </animated.div>
                                    )}
                                    {openArticle !== 'subservice1' && <div className="line"></div>}
                                </div>

                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('subservice2')} aria-expanded={openArticle === 'subservice2'}>
                                        <animated.span style={{ transform: openArticle === 'subservice2' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'subservice2' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.tst3")}</p>
                                    </button>
                                    {openArticle === 'subservice2' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="normalcolapsText">{t("Buying_guide.tst17")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tst18")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tst19")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tst20")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tst21")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tst22")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tst23")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tst24")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tst25")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tst26")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tst27")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tst29")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tst30")}</p>
                                            {/* <div className="line"></div> */}
                                        </animated.div>
                                    )}
                                    {openArticle !== 'subservice2' && <div className="line"></div>}
                                </div>

                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('subservice3')} aria-expanded={openArticle === 'subservice3'}>
                                        <animated.span style={{ transform: openArticle === 'subservice3' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'subservice3' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.tst4")}</p>
                                    </button>
                                    {openArticle === 'subservice3' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="normalcolapsText">{t("Buying_guide.tsk")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tsk1")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tsk2")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tsk3")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tsk4")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tsk5")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tsk6")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tsk7")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tsk8")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tsk9")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tsk10")}</p>
                                            {/* <div className="line"></div> */}
                                        </animated.div>
                                    )}
                                    {openArticle !== 'subservice3' && <div className="line"></div>}
                                </div>

                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('subservice4')} aria-expanded={openArticle === 'subservice4'}>
                                        <animated.span style={{ transform: openArticle === 'subservice4' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'subservice4' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.tst5")}</p>
                                    </button>
                                    {openArticle === 'subservice4' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="normalcolapsText">{t("Buying_guide.tsk11")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tsk12")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tsk13")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tsk14")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tsk15")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tsk16")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tsk17")}</p>
                                            {/* <div className="line"></div> */}
                                        </animated.div>
                                    )}
                                    {openArticle !== 'subservice4' && <div className="line"></div>}
                                </div>

                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('subservice5')} aria-expanded={openArticle === 'subservice5'}>
                                        <animated.span style={{ transform: openArticle === 'subservice5' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'subservice5' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.tst6")}</p>
                                    </button>
                                    {openArticle === 'subservice5' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="normalcolapsText">{t("Buying_guide.tsk18")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tsk19")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tsk20")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tsk21")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tsk22")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tsk23")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tsk24")}</p>
                                            <div className="backgroundsnowwhite">

                                                <div className="snow">
                                                    <p className="titlenormalcolapsText">{t("Buying_guide.tsk25")}</p>
                                                    <p className="titlenormalcolapsTextULX">{t("Buying_guide.tsk26")}</p>

                                                    <div className="widthbutton">
                                                        <Link to="/SendMessage" state={{ MessagTitle: "خطط التقسيط" }}>
                                                            <button className="sendbtnOne sendbtn">
                                                                {t("security.send")}
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="line"></div> */}
                                        </animated.div>
                                    )}
                                    {openArticle !== 'subservice5' && <div className="line"></div>}
                                </div>
                            </div>
                        </animated.div>
                    )}
                    {!openSection && <div className="line"></div>}
                </div>


                <div>
                    <button className="button_mada" onClick={() => toggleSection('ourSpecification')} aria-expanded={openSection === 'ourSpecification'}>
                        <animated.span style={{ transform: openSection === 'ourSpecification' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                            {openSection === 'ourSpecification' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </animated.span>
                        <p className="textCont">{t("Buying_guide.tst")}</p>
                    </button>
                    {openSection === 'ourSpecification' && (
                        <animated.div style={{
                            backgroundColor: '#F4F6FA',
                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                        }}>
                            <div id="ourSpecification" className={`ContactU ${openSection === 'ourSpecification' ? 'open' : 'closed'}`}>
                                <p className="normalcolapsText">{t("Buying_guide.syr")}</p>
                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('ourSpecification1')} aria-expanded={openArticle === 'ourSpecification1'}>
                                        <animated.span style={{ transform: openArticle === 'ourSpecification1' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'ourSpecification1' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.kik14")}</p>
                                    </button>
                                    {openArticle === 'ourSpecification1' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="normalcolapsText">{t("Buying_guide.syr1")}</p>
                                            <ul>
                                                <li className="titlenormalcolapsText">{t("Buying_guide.syr2")}</li>
                                                <ul>
                                                    <li className="titlenormalcolapsTextULX">{t("Buying_guide.syr3")}</li>
                                                    <li className="titlenormalcolapsTextULX">{t("Buying_guide.syr4")}</li>
                                                </ul>
                                                <li className="titlenormalcolapsText">{t("Buying_guide.syr5")}</li>
                                                <ul>
                                                    <li className="titlenormalcolapsTextULX">{t("Buying_guide.syr6")}</li>
                                                    <li className="titlenormalcolapsTextULX">{t("Buying_guide.syr7")}</li>
                                                </ul>
                                                <li className="titlenormalcolapsText">{t("Buying_guide.syr8")}</li>
                                                <ul>
                                                    <li className="titlenormalcolapsTextULX">{t("Buying_guide.syr9")}</li>
                                                    <li className="titlenormalcolapsTextULX">{t("Buying_guide.syr10")}</li>
                                                </ul>
                                                <li className="titlenormalcolapsText">{t("Buying_guide.syr11")}</li>
                                                <ul>
                                                    <li className="titlenormalcolapsTextULX">{t("Buying_guide.syr12")}</li>
                                                    <li className="titlenormalcolapsTextULX">{t("Buying_guide.syr13")}</li>
                                                </ul>
                                            </ul>
                                            {/* <div className="line"></div> */}
                                        </animated.div>
                                    )}
                                    {openArticle !== 'ourSpecification1' && <div className="line"></div>}
                                </div>

                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('recommendations')} aria-expanded={openArticle === 'recommendations'}>
                                        <animated.span style={{ transform: openArticle === 'recommendations' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'recommendations' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.sectionTitle")}</p>
                                    </button>
                                    {openArticle === 'recommendations' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="normalcolapsText">{t("Buying_guide.intro")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.recommendationsFromFamily")}</p>
                                            <ul>
                                                <li className="titlenormalcolapsTextULX">{t("Buying_guide.familyDetails1")}</li>
                                                <li className="titlenormalcolapsTextULX">{t("Buying_guide.familyDetails2")}</li>
                                            </ul>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.recommendationsFromProfessionals")}</p>
                                            <ul>
                                                <li className="titlenormalcolapsTextULX">{t("Buying_guide.professionalDetails1")}</li>
                                                <li className="titlenormalcolapsTextULX">{t("Buying_guide.professionalDetails2")}</li>
                                            </ul>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.writtenRecommendations")}</p>
                                            <ul>
                                                <li className="titlenormalcolapsTextULX">{t("Buying_guide.writtenDetails1")}</li>
                                                <li className="titlenormalcolapsTextULX">{t("Buying_guide.writtenDetails2")}</li>
                                            </ul>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.previousUsersExperience")}</p>
                                            <ul>
                                                <li className="titlenormalcolapsTextULX">{t("Buying_guide.userExperienceDetails1")}</li>
                                                <li className="titlenormalcolapsTextULX">{t("Buying_guide.userExperienceDetails2")}</li>
                                            </ul>
                                            {/* <div className="line"></div> */}
                                        </animated.div>
                                    )}
                                    {openArticle !== 'recommendations' && <div className="line"></div>}
                                </div>

                                <div className="backgroundsnowwhite">
                                    <div className="columncontact">
                                        <p className="titlenormalcolapsText">{t("Buying_guide.firstPart")}</p>
                                        <p className="titlenormalcolapsTextULX">{t("Buying_guide.secondPart")}</p>
                                        <p className="titlenormalcolapsTextULX">{t("Buying_guide.thirdPart")}</p>
                                        <p className="titlenormalcolapsTextULX">{t("Buying_guide.foPart")}</p>
                                        <p className="titlenormalcolapsTextULX">{t("Buying_guide.faPart")}</p>
                                        <div className="snow">
                                            <div className="widthbutton">
                                            
                                                <Link to="/SendMessage" state={{ MessagTitle: "شكوى" }}>
                                                    <button>
                                                        {t("Slideshow.txtQuestionD1")}
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </animated.div>
                    )}
                    {!openSection && <div className="line"></div>}
                </div>


                <div>
                    <button className="button_mada" onClick={() => toggleSection('contact')} aria-expanded={openSection === 'contact'}>
                        <animated.span style={{ transform: openSection === 'contact' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                            {openSection === 'contact' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </animated.span>
                        <p className="textCont">{t("Buying_guide.tit9")}</p>
                    </button>
                    {openSection === 'contact' && (
                        <animated.div style={{
                            backgroundColor: '#F4F6FA',
                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                        }}>
                            <div id="contact" className={`ContactU ${openSection === 'contact' ? 'open' : 'closed'}`}>
                                <p className="normalcolapsText">{t("Buying_guide.tot")}</p>
                                {/* <div className="line"></div> */}
                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('tot27')} aria-expanded={openArticle === 'tot27'}>
                                        <animated.span style={{ transform: openArticle === 'tot27' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'tot27' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.sad2")}</p>
                                    </button>
                                    {openArticle === 'tot27' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.ksk1")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.ksk2")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.ksk3")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.ksk4")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.ksk5")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.ksk6")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.ksk7")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.ksk8")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.ksk9")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.ksk10")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.ksk11")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.ksk12")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.ksk13")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.ksk14")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.ksk15")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.ksk16")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.ksk17")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.ksk18")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.ksk19")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.ksk20")}</p>
                                            {/* <div className="backgroundsnowwhite">
                                                <div className="columncontact">
                                                    <p className="titlenormalcolapsText">{t("Buying_guide.ksk21")}</p>
                                                    <p className="titlenormalcolapsTextULX">{t("Buying_guide.ksk22")}</p>
                                                    <p className="titlenormalcolapsTextULX">{t("Buying_guide.ksk23")}</p>
                                                    <p className="titlenormalcolapsTextULX">{t("Buying_guide.ksk24")}</p>
                                                    <p className="titlenormalcolapsTextULX">{t("Buying_guide.ksk25")}</p>
                                                    <p className="titlenormalcolapsTextULX">{t("Buying_guide.ksk26")}</p>
                                                    <div className="snow">
                                                        <div className="widthbutton">
                                                            <button className="sendbtnOne sendbtn" onClick={() => window.open('mailto:info@example.com')}>
                                                             {t("security.send")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className="backgroundsnowwhite">
                                                <div className="columncontact">
                                                    <p className="titlenormalcolapsText">{t("Buying_guide.ksk21")}</p>
                                                    <p className="titlenormalcolapsTextULX">{t("Buying_guide.ksk22")}</p>
                                                    <p className="titlenormalcolapsTextULX">{t("Buying_guide.ksk23")}</p>
                                                    <p className="titlenormalcolapsTextULX">{t("Buying_guide.ksk24")}</p>
                                                    <p className="titlenormalcolapsTextULX">{t("Buying_guide.ksk25")}</p>
                                                    <p className="titlenormalcolapsTextULX">{t("Buying_guide.ksk26")}</p>
                                                    <div className="snow">

                                                        <div className="widthbutton">
                                                            <Link to="/SendMessage" state={{ MessagTitle: "مشكله بحث" }}>
                                                                <button className="sendbtn">
                                                                    {t("security.send")}
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.bob1")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.bob2")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.bob3")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.bob4")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.bob5")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.bob6")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.bob7")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.bob8")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.bob9")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.bob10")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.bob11")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.bob12")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.bob13")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.bob14")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.bob15")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.bob16")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.bob17")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.bob18")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.bob19")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.bob20")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.bob21")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.bob22")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.bob23")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.bob24")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.bob25")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.bob26")}</p>
                                            <div className="backgroundsnowwhite">
                                                <div className="columncontact">
                                                    <p className="titlenormalcolapsText">{t("Buying_guide.bob28")}</p>
                                                    <p className="titlenormalcolapsTextULX">{t("Buying_guide.bob29")}</p>
                                                    <div className="habaa">
                                                        <button className="sendbtnOne sendbtn" onClick={() => window.open('tel:+966599489990')}>
                                                            {t("Buying_guide.bob30")}
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                            {/* <div className="line"></div> */}
                                        </animated.div>
                                    )}
                                    {openArticle !== 'tot27' && <div className="line"></div>}
                                </div>
                            </div>
                        </animated.div>
                    )}
                    {!openSection && <div className="line"></div>}
                </div>


                <div>
                    <button className="button_mada" onClick={() => toggleSection('ourSpecificationend')} aria-expanded={openSection === 'ourSpecificationend'}>
                        <animated.span style={{ transform: openSection === 'ourSpecificationend' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                            {openSection === 'ourSpecificationend' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </animated.span>
                        <p className="textCont">{t("Buying_guide.ourSpecificationend")}</p>
                    </button>
                    {openSection === 'ourSpecificationend' && (
                        <animated.div style={{
                            backgroundColor: '#F4F6FA',
                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                        }}>
                            <div id="ourSpecificationend" className={`ContactU ${openSection === 'ourSpecificationend' ? 'open' : 'closed'}`}>
                                <p className="normalcolapsText">{t("Buying_guide.tot")}</p>
                                {/* <div className="line"></div> */}
                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('ourSpecificationend1')} aria-expanded={openArticle === 'ourSpecificationend1'}>
                                        <animated.span style={{ transform: openArticle === 'ourSpecificationend1' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'ourSpecificationend1' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.FAQs3")}</p>
                                    </button>
                                    {openArticle === 'ourSpecificationend1' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tot1")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot2")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot3")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tot5")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot4")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tot6")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot7")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot8")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tot9")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot10")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot11")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot12")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tot13")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot14")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot15")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tot16")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot17")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot18")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tot19")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot20")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot21")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tot22")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot23")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot24")}</p>
                                            <p className="titlenormalcolapsText">{t("Buying_guide.tot25")}</p>
                                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot26")}</p>
                                            {/* <div className="line"></div> */}
                                        </animated.div>
                                    )}
                                    {openArticle !== 'ourSpecificationend1' && <div className="line"></div>}
                                </div>
                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('recommendations')} aria-expanded={openArticle === 'recommendations'}>
                                        <animated.span style={{ transform: openArticle === 'recommendations' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'recommendations' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.tot27")}</p>
                                    </button>
                                    {openArticle === 'recommendations' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <div className="backgroundsnowwhite">
                                                <div className="columncontact">
                                                    <p className="titlenormalcolapsText">{t("Buying_guide.tot27")}</p>
                                                    <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot28")}</p>

                                                    <div className="habaa">
                                                        <Link to="/SendMessage" state={{ MessagTitle: "تواصل" }}>
                                                            <button className="sendbtnOne sendbtn" >
                                                                {t("Buying_guide.tot29")}
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="line"></div> */}
                                        </animated.div>
                                    )}
                                    {openArticle !== 'recommendations' && <div className="line"></div>}
                                </div>
                            </div>
                        </animated.div>
                    )}
                    {!openSection && <div className="line"></div>}
                </div>




                <div>
                    <button className="button_mada" onClick={() => toggleSection('FAQs')} aria-expanded={openSection === 'FAQs'}>
                        <animated.span style={{ transform: openSection === 'FAQs' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                            {openSection === 'FAQs' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </animated.span>
                        <p className="textConta">{t("Buying_guide.FAQs")}</p>
                    </button>
                    {openSection === 'FAQs' && (
                        <animated.div style={{
                            backgroundColor: '#F4F6FA',
                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                        }}>
                            <div id="FAQs" className={`ContactU ${openSection === 'FAQs' ? 'open' : 'closed'}`}>
                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('FAQs1')} aria-expanded={openArticle === 'FAQs1'}>
                                        <animated.span style={{ transform: openArticle === 'FAQs1' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'FAQs1' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.FAQs1")}</p>
                                    </button>
                                    {openArticle === 'FAQs1' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="normalcolapsText">{t("Buying_guide.FAQs2")}</p>
                                        </animated.div>
                                    )}
                                    {openArticle !== 'FAQs1' && <div className="line"></div>}
                                </div>

                                <div className="articleContent whiteH">
                                    <button className="button-collapses" onClick={() => toggleArticle('subFAQs8')} aria-expanded={openArticle === 'subFAQs8'}>
                                        <animated.span style={{ transform: openArticle === 'subFAQs8' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                            {openArticle === 'subFAQs8' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </animated.span>
                                        <p className="textCont">{t("Buying_guide.FAQs3")}</p>
                                    </button>
                                    {openArticle === 'subFAQs8' && (
                                        <animated.div style={{
                                            backgroundColor: '#F4F6FA',
                                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                                        }}>
                                            <p className="normalcolapsText">{t("Buying_guide.FAQs4")}</p>
                                        </animated.div>
                                    )}
                                    {openArticle !== 'subFAQs8' && <div className="line"></div>}
                                </div>

                                <div className="articleContent whiteH">
              <button className="button-collapses" onClick={() => toggleArticle('ourSpecificationend1')} aria-expanded={openArticle === 'ourSpecificationend1'}>
                <animated.span style={{ transform: openArticle === 'ourSpecificationend1' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                  {openArticle === 'ourSpecificationend1' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </animated.span>
                <p className="textCont">{t("Buying_guide.FAQs3")}</p>
              </button>
              {openArticle === 'ourSpecificationend1' && (
                <animated.div style={{
                  backgroundColor: '#F4F6FA',
                  borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                }}>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot1")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot2")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot3")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot5")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot4")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot6")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot7")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot8")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot9")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot10")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot11")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot12")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot13")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot14")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot15")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot16")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot17")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot18")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot19")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot20")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot21")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot22")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot23")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot24")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot25")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot26")}</p>
                  <div className="backgroundsnowwhite">
                    <div className="columncontact ">
                      <p className="titlenormalcolapsText">{t("Buying_guide.tot27")}</p>
                      <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot28")}</p>

                      <div className="habaa">
                        <Link to="/SendMessage" state={{ MessagTitle: "تواصل" }}>
                          <button className="sendbtnOne sendbtn">
                            {t("Buying_guide.tot29")}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </animated.div>
              )}
              {openArticle !== 'ourSpecificationend1' && <div className="line"></div>}
            </div>

            <div className="articleContent whiteH">
              <button className="button-collapses" onClick={() => toggleArticle('subFAQs10')} aria-expanded={openArticle === 'subFAQs10'}>
                <animated.span style={{ transform: openArticle === 'subFAQs10' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                  {openArticle === 'subFAQs10' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </animated.span>
                <p className="textCont">{t("Buying_guide.FAQs7")}</p>
              </button>
              {openArticle === 'subFAQs10' && (
                <animated.div style={{
                  backgroundColor: '#F4F6FA',
                  borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                }}>
                  <p className="normalcolapsText">{t("Buying_guide.FAQs8")}</p>
                </animated.div>
              )}
              {openArticle !== 'subFAQs10' && <div className="line"></div>}
            </div>

            <div className="articleContent whiteH">
              <button className="button-collapses" onClick={() => toggleArticle('subFAQs11')} aria-expanded={openArticle === 'subFAQs11'}>
                <animated.span style={{ transform: openArticle === 'subFAQs11' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                  {openArticle === 'subFAQs11' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </animated.span>
                <p className="textCont">{t("Buying_guide.FAQs9")}</p>
              </button>
              {openArticle === 'subFAQs11' && (
                <animated.div style={{
                  backgroundColor: '#F4F6FA',
                  borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                }}>
                  <p className="normalcolapsText">{t("Buying_guide.FAQs10")}</p>
                </animated.div>
              )}
              {openArticle !== 'subFAQs11' && <div className="line"></div>}
            </div>

            <div className="articleContent whiteH">
              <button className="button-collapses " onClick={() => toggleArticle('subFAQs12')} aria-expanded={openArticle === 'subFAQs12'}>
                <animated.span style={{ transform: openArticle === 'subFAQs12' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                  {openArticle === 'subFAQs12' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </animated.span>
                <p className="textCont">{t("Buying_guide.FAQs11")}</p>
              </button>
              {openArticle === 'subFAQs12' && (
                <animated.div style={{
                  backgroundColor: '#F4F6FA',
                  borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                }}>
                  <p className="normalcolapsText">{t("Buying_guide.FAQs12")}</p>
                </animated.div>
              )}
            </div>
                            </div>
                        </animated.div>
                    )}
                    {!openSection && <div className="line"></div>}
                </div>






                <div className="backgroundsnowwhite">
                    <div className="columncontact">
                        <div className="snow">
                            <br />
                        </div>

                        <p className="titlenormalcolapsText">{t("Buying_guide.1")}</p>
                        <div className="snow">
                            <p className="titlenormalcolapsTextULX">{t("Buying_guide.tit101")}</p>
                            <br />
                            <div className="buttonRow">
                                <div className="widthbutton">

                                    <Link to="/SendMessage" state={{ MessagTitle: "سؤال" }}>
                                        <button className="sendbtn">
                                            {t("Buying_guide.1")}
                                        </button>
                                    </Link>
                                </div>
                                <div className="widthbutton">
                                    <Link to="/SendMessage" state={{ MessagTitle: "دردش" }}>
                                        <button className="sendbtn">
                                            {t("Buying_guide.2")}
                                        </button>
                                    </Link>
                                </div>
                                <div className="widthbutton">


                                    <Link to="/SendMessage" state={{ MessagTitle: "طوارئ" }}>
                                        <button className="sendbtn">
                                            {t("Buying_guide.3")}
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="line"></div> */}


                <br />



            </div>
        </div >
    );
};

export default Buying_guide;
