
// import { Link } from 'react-router-dom';
// import React, { useEffect } from "react";
// import { useTranslation } from 'react-i18next';
// import Madalogo from './images/payment_option/Mada_footerlogo.png';
// import mclogo from './images/payment_option/mc_footerlogo.png';
// import tabbylogo from './images/payment_option/tabby_new.png';
// import visalogo from './images/payment_option/visa_footerlogo.png';
// import './Footer.css';

// const Footer = () => {
//   const { t, i18n } = useTranslation("global");

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const getDirection = () => {
//     return i18n.language === "ar" ? "rtl" : "ltr";
//   };

//   return (
//     <div className='footer-container mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300' style={{ direction: getDirection() }}>

//       {/* Solutions, Services, and Contact Information */}
//       <div className='lg:col-span-2 flex flex-col lg:flex-row justify-between'>
//         <div className='mb-8 lg:mb-0'>
//           <h6 style={{ fontWeight: 'bold', fontSize: '1.25rem', color: 'white' }}>{t("footer.solutions")}</h6>
//           <ul className='mt-2 lg:mr-8'>
//             <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
//               <Link to={"/Est_Services"} style={{ fontSize: '1rem', color: 'white' }}>
//                 {t("footer.Est_Services")}
//               </Link>
//             </li>
//             <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
//               <Link to={"/Service_providers"} style={{ fontSize: '1rem', color: 'white' }}>
//                 {t("footer.legal_assistance")}
//               </Link>
//             </li>
//             <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
//               <Link to={"/Job_applications"} style={{ fontSize: '1rem', color: 'white' }}>
//                 {t("footer.Job_applications")}
//               </Link>
//             </li>
//             <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
//               <Link to={"/Establishment_Services_assistance"} style={{ fontSize: '1rem', color: 'white' }}>
//                 {t("footer.Establishment_Services_assistance")}
//               </Link>
//             </li>
//           </ul>
//         </div>
//         <div className='mb-8 lg:mb-0'>
//           <h6 style={{ fontWeight: 'bold', fontSize: '1.25rem', color: 'white' }}>{t("footer.service")}</h6>
//           <ul className='mt-2 lg:mr-8'>
//             <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
//               <Link className=''
//                 to="/support"
//               > <p className='footerFontWhite'>{t("footer.support")}</p>
//               </Link>
//             </li>
//             <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
//               <Link to={"/automation"} style={{ fontSize: '1rem', color: 'white' }}>
//                 {t("footer.news")}
//               </Link>
//             </li>
//           </ul>
//         </div>
//         <div className='mb-8 lg:mb-0'>
//           <h6 style={{ fontWeight: 'bold', fontSize: '1.25rem', color: 'white' }}>{t("footer.help")}</h6>
//           <ul className='mt-2 lg:mr-8'>
//             <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
//               <Link to={"/contact_us"} style={{ fontSize: '1rem', color: 'white' }}>
//                 {t("footer.help")}
//               </Link>
//             </li>

//             <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
//               <Link style={{ color: '#FFFFFF' }} to="/SendMessage" state={{ MessagTitle: "شكوى" }}>
//                 {t("footer.File_a_complaint")}
//               </Link>
//             </li>
//             <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
//               <Link style={{ color: '#FFFFFF' }} to="/SendMessage" state={{ MessagTitle: "اعتراض" }}>
//                 {t("footer.File_an_objection")}
//               </Link>
//             </li>
//           </ul>
//         </div>
//         <div>
//           <h6 style={{ fontWeight: 'bold', fontSize: '1.25rem', color: 'white' }}>{t("footer.contact_us")}</h6>
//           <ul className='mt-2'>
//             <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
//               <a className="py-2 text-white" href={" https://www.google.com/maps/place/%D9%85%D9%86%D8%B5%D8%A9+%D9%84%D9%87%D8%A7+%D9%84%D9%83+%D8%A7%D9%84%D8%B7%D8%A8%D9%8A%D8%A9%E2%80%AD/@21.5256193,39.1699823,18.04z/data=!4m6!3m5!1s0x15c3d15649200e27:0x6d056ff84ef35803!8m2!3d21.5257418!4d39.1696576!16s%2Fg%2F11ptlqg17k?entry=ttu"}
//               >
//                 {t("footer.jeddah_address")}
//               </a>

//             </li>
//             <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>{t("footer.riyadh_address")}</li>
//             <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>920014308</li>

//             <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>+966 57 160 5516</li>
//             <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>{t("footer.email")}</li>
//           </ul>
//         </div>
//       </div>

//       <div className="lg:col-span-2 flex flex-col lg:flex-row justify-between">
//         <Link to={"/Contract_provisions"} >
//           <p style={{ color: '#FFFFFF' }}>{t("footer.faq")}</p>
//         </Link>
//         <Link to={"/privacy_policy"} >
//           <p style={{ color: '#FFFFFF' }}>{t("footer.privacy_policy")}</p>
//         </Link>
//         <Link to={"/Terms_and_conditions"} >
//           <p style={{ color: '#FFFFFF' }}>{t("footer.terms_and_conditions")}</p>
//         </Link>
//         <Link to={"/Privacy"} >
//           <p style={{ color: '#FFFFFF' }}>{t("footer.privce")}</p>
//         </Link>
//       </div>


//       <div className="flex justify-start mt-4">
//         <img src={Madalogo} alt="Mada" className="mx-2 h-6" />
//         <img src={mclogo} alt="MasterCard" className="mx-2 h-6" />
//         <img src={tabbylogo} alt="Tabby" className="mx-2 h-6" />
//         <img src={visalogo} alt="Visa" className="mx-2 h-6" />
//       </div>
//       <div className='centre'>
//         <p className='text-sm text-gray-400' style={{ fontSize: '1rem', fontWeight: 'bold' }}>
//           &copy; جميع حقوق البيع والنشر والتوزيع محفوظة.
//         </p>
//       </div>
//     </div>
//   );
// };

// export default Footer;
import { Link } from 'react-router-dom';
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Madalogo from './images/payment_option/Mada_footerlogo.png';
import mclogo from './images/payment_option/mc_footerlogo.png';
// import tabbylogo from './images/payment_option/tabby_new.png';
import { FaClock, FaThumbsUp, FaQuestionCircle, FaCheckCircle, FaUsers, FaTachometerAlt, FaMedal, FaEnvelopeOpenText, FaStethoscope, FaUserFriends } from 'react-icons/fa';

import visalogo from './images/payment_option/visa_footerlogo.png';
import './Footer.css';

const Footer = () => {
  const { t, i18n } = useTranslation("global");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };

  return (
    <div className='footer-container mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300' style={{ direction: getDirection() }}>
      <div className="godfree lg:col-span-3 flex flex-col items-center justify-center">
      <h2 className="partner-heading">{t("footer.partnersTitle")}</h2>

        <div className="lucifer-container flex flex-wrap justify-center items-center mb-8">

        <div className="lucifer1 text-center flex flex-col items-center mb-4 lg:mb-0 w-full sm:w-1/2 lg:w-auto">
            <img src="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/CARE-FOR-U-ksa--e1711841958956-1024x872.png?alt=media&token=cc54f303-641c-452b-b773-8ba5938385cb" alt=" كير فور يو" className="lucifer-logo mb-2" />
            <p className="lucifer-subtext">{t("footer.careforyou")}</p>
          </div>
          
          <div className="lucifer1 text-center flex flex-col items-center mb-4 lg:mb-0 w-full sm:w-1/2 lg:w-auto">
            <img src="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/y0GW3Z0K_400x400%20(1).jpg?alt=media&token=493c476c-07ee-4c7f-8d47-ae8ada150713" alt="مستشفى السعودي الألماني" className="lucifer-logo mb-2" />
            <p className="lucifer-subtext">{t("footer.saudiAlmani")}</p>
          </div>
          <div className="lucifer2 text-center flex flex-col items-center mb-4 lg:mb-0 w-full sm:w-1/2 lg:w-auto">
            <img src="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/andalusia.png?alt=media&token=3ad14da9-a5ef-41b2-81d7-aef6ca177081" alt="مستشفى الأندلسية" className="lucifer-logo mb-2" />
            <p className="lucifer-subtext">{t("footer.andalusi")}</p>
          </div>
          <div className="lucifer3 text-center flex flex-col items-center mb-4 lg:mb-0 w-full sm:w-1/2 lg:w-auto">
            <img src="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/passport_fc84a337-3840-4dd4-bacf-671c391447c6.png?alt=media&token=4ebfc2aa-3852-446b-9fee-743bc5c2b798" alt="مستشفى الدكتور عرفان العام" className="lucifer-logo mb-2" />
            <p className="lucifer-subtext">{t("footer.arfan")}</p>
          </div>
          <div className="lucifer4 text-center flex flex-col items-center mb-4 lg:mb-0 w-full sm:w-1/2 lg:w-auto">
            <img src="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/Alia_Al_Farid.png?alt=media&token=52157d0e-bf5e-48d8-a91e-9516e9e3605e" alt="مجمع عالية الفريد الطبي" className="lucifer-logo mb-2" />
            <p className="lucifer-subtext">{t("footer.aliya")}</p>
          </div>
        </div>
      </div>


      <div className="Chloe-container lg:col-span-3 flex justify-around items-center mb-8">
        <div className="Chloe1 text-center">
          <FaTachometerAlt className="Chloe-icon mb-2" />  {/* Speedometer icon for "Speed of Response" */}
          <p className="Chloe-text">{t("footer.responseSpeedValue")}</p>
          <p className="Chloe-subtext">{t("footer.responseSpeedLabel")}</p>
        </div>
        <div className="Chloe2 text-center">
          <FaMedal className="Chloe-icon mb-2" />  {/* Medal icon for "Medical Center Satisfaction" */}
          <p className="Chloe-text">{t("footer.medicalCenterSatisfactionValue")}</p>
          <p className="Chloe-subtext">{t("footer.medicalCenterSatisfactionLabel")}</p>
        </div>
        <div className="Chloe3 text-center">
          <FaEnvelopeOpenText className="Chloe-icon mb-2" />  {/* Open envelope icon for "Customer Inquiries Answered" */}
          <p className="Chloe-text">{t("footer.customerInquiriesAnsweredValue")}</p>
          <p className="Chloe-subtext">{t("footer.customerInquiriesAnsweredLabel")}</p>
        </div>
        <div className="Chloe4 text-center">
          <FaStethoscope className="Chloe-icon mb-2" />  {/* Stethoscope icon for "Successful Medical Examinations" */}
          <p className="Chloe-text">{t("footer.successfulMedicalExaminationsValue")}</p>
          <p className="Chloe-subtext">{t("footer.successfulMedicalExaminationsLabel")}</p>
        </div>
        <div className="Chloe5 text-center">
          <FaUserFriends className="Chloe-icon mb-2" /> 
          <p className="Chloe-text">{t("footer.customersValue")}</p>
          <p className="Chloe-subtext">{t("footer.customersLabel")}</p>
        </div>
      </div>





      <div className='lg:col-span-2 flex flex-col lg:flex-row justify-between'>
        <div className='mb-8 lg:mb-0'>
          <h6 style={{ fontWeight: 'bold', fontSize: '1.25rem', color: 'white' }}>{t("footer.solutions")}</h6>
          <ul className='mt-2 lg:mr-8'>
            <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
              <Link to={"/Est_Services"} style={{ fontSize: '1rem', color: 'white' }}>
                {t("footer.Est_Services")}
              </Link>
            </li>
            <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
              <Link to={"/Service_providers"} style={{ fontSize: '1rem', color: 'white' }}>
                {t("footer.legal_assistance")}
              </Link>
            </li>
            <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
              <Link to={"/Job_applications"} style={{ fontSize: '1rem', color: 'white' }}>
                {t("footer.Job_applications")}
              </Link>
            </li>
            <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
              <Link to={"/Establishment_Services_assistance"} style={{ fontSize: '1rem', color: 'white' }}>
                {t("footer.Establishment_Services_assistance")}
              </Link>
            </li>
          </ul>
        </div>
        <div className='mb-8 lg:mb-0'>
          <h6 style={{ fontWeight: 'bold', fontSize: '1.25rem', color: 'white' }}>{t("footer.service")}</h6>
          <ul className='mt-2 lg:mr-8'>
            <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
              <Link className=''
                to="/support"
              > <p className='footerFontWhite'>{t("footer.support")}</p>
              </Link>
            </li>
            <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
              <Link to={"/automation"} style={{ fontSize: '1rem', color: 'white' }}>
                {t("footer.news")}
              </Link>
            </li>
          </ul>
        </div>
        <div className='mb-8 lg:mb-0'>
          <h6 style={{ fontWeight: 'bold', fontSize: '1.25rem', color: 'white' }}>{t("footer.help")}</h6>
          <ul className='mt-2 lg:mr-8'>
            <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
              <Link to={"/contact_us"} style={{ fontSize: '1rem', color: 'white' }}>
                {t("footer.help")}
              </Link>
            </li>

            <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
              <Link style={{ color: '#FFFFFF' }} to="/SendMessage" state={{ MessagTitle: "شكوى" }}>
                {t("footer.File_a_complaint")}
              </Link>
            </li>
            <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
              <Link style={{ color: '#FFFFFF' }} to="/SendMessage" state={{ MessagTitle: "اعتراض" }}>
                {t("footer.File_an_objection")}
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <h6 style={{ fontWeight: 'bold', fontSize: '1.25rem', color: 'white' }}>{t("footer.contact_us")}</h6>
          <ul className='mt-2'>
            <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
              <a className="py-2 text-white" href={" https://www.google.com/maps/place/%D9%85%D9%86%D8%B5%D8%A9+%D9%84%D9%87%D8%A7+%D9%84%D9%83+%D8%A7%D9%84%D8%B7%D8%A8%D9%8A%D8%A9%E2%80%AD/@21.5256193,39.1699823,18.04z/data=!4m6!3m5!1s0x15c3d15649200e27:0x6d056ff84ef35803!8m2!3d21.5257418!4d39.1696576!16s%2Fg%2F11ptlqg17k?entry=ttu"}
              >
                {t("footer.jeddah_address")}
              </a>

            </li>
            <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>{t("footer.riyadh_address")}</li>
            <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>920014308</li>

            <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>+966 57 160 5516</li>
            <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>{t("footer.email")}</li>
          </ul>
        </div>
      </div>

      <div className="lg:col-span-2 flex flex-col lg:flex-row justify-between">
        <Link to={"/Contract_provisions"} >
          <p style={{ color: '#FFFFFF' }}>{t("footer.faq")}</p>
        </Link>
        <Link to={"/privacy_policy"} >
          <p style={{ color: '#FFFFFF' }}>{t("footer.privacy_policy")}</p>
        </Link>
        <Link to={"/Terms_and_conditions"} >
          <p style={{ color: '#FFFFFF' }}>{t("footer.terms_and_conditions")}</p>
        </Link>
        <Link to={"/Privacy"} >
          <p style={{ color: '#FFFFFF' }}>{t("footer.privce")}</p>
        </Link>
      </div>


      <div className="flex justify-start mt-4">
        <img src={Madalogo} alt="Mada" className="mx-2 h-6" />
        <img src={mclogo} alt="MasterCard" className="mx-2 h-6" />
        {/* <img src={tabbylogo} alt="Tabby" className="mx-2 h-6" /> */}
        <img src={visalogo} alt="Visa" className="mx-2 h-6" />
      </div>
      <div className='centre'>
        <p className='text-sm text-gray-400' style={{ fontSize: '1rem', fontWeight: 'bold' }}>
          &copy; جميع حقوق البيع والنشر والتوزيع محفوظة.
        </p>
      </div>
    </div>
  );
};

export default Footer;
