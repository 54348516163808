import React, { useState, useEffect, useRef } from 'react';
import './Counter.css';
import { useTranslation } from 'react-i18next';

// Helper function to convert numbers to Arabic numerals
function convertToArabicNumbers(input) {
    const arabicNumbers = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
    return input.toString().replace(/[0-9]/g, (digit) => arabicNumbers[digit]);
}

function Counter({ from, to, label }) {
    const { i18n } = useTranslation();
    const [count, setCount] = useState(from);
    const [isVisible, setIsVisible] = useState(false);
    const counterRef = useRef(null);
    const duration = 5000;  // Total duration of the animation

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(counterRef.current);
                }
            },
            { threshold: 0.1 }
        );
        if (counterRef.current) {
            observer.observe(counterRef.current);
        }
        return () => {
            if (counterRef.current) {
                observer.unobserve(counterRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (!isVisible) return;

        let frameRate = 1000 / 60;  // 60 FPS
        let totalFrames = Math.round(duration / frameRate);
        let counter = from;
        let increment = (to - from) / totalFrames;

        const timer = setInterval(() => {
            counter += increment;
            setCount(Math.min(Math.round(counter), to));  // Ensure it does not exceed 'to'
            if (counter >= to) {
                clearInterval(timer);
            }
        }, frameRate);

        return () => clearInterval(timer);
    }, [isVisible, from, to]);

    const formatNumber = (num) => {
        const formattedNumber = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return i18n.language === 'ar' ? convertToArabicNumbers(formattedNumber) : formattedNumber;
    };

    return (
        <div className="bcounter" ref={counterRef}>
            <span className="counter">{formatNumber(count)}</span>
            <p className='marwaha'>{label}</p>
        </div>
    );
}

function CounterBox() {
    const { t, i18n } = useTranslation("global");

    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    return (
        <div style={{ direction: getDirection() }} className='counterbox'>
            <Counter from={0} to={11000} label={t("Counter.Clients")} />
            {/* <Counter from={0} to={3063} label={t("Counter.Ideas")} /> */}
            <Counter from={0} to={91} label={t("Counter.Response Speed (%)")} />
            <Counter from={0} to={5850} label={t("Counter.Successful Birth Referrals")} />
            <Counter from={0} to={98} label={t("Counter.Medical Center Satisfaction (%)")} />
            <Counter from={0} to={870} label={t("Counter.Successful Emergency Birth Referrals")} />
            <Counter from={0} to={1000000} label={t("Counter.Customer Inquiries Answered")} />
            <Counter from={0} to={6200} label={t("Counter.Successful Medical Examinations")} />
            <Counter from={0} to={85} label={t("Counter.Customer Return Rate (%)")} /> 
            <Counter from={0} to={4.8} label={t("Counter.Customer Satisfaction (%)")} />
        </div>
    ); 
}

export default CounterBox;
