import LoginIcon from "@mui/icons-material/Login";
import PhoneIcon from "@mui/icons-material/Phone";
import "./Login.css";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Button, CircularProgress } from "@mui/material";

const Login = () => {
  const { t, i18n } = useTranslation("global");
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false); // Loading state for better UX

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading on form submit
    const { phone, password } = e.target.elements;

    try {
      const response = await fetch("https://lahalak.sa/backend/auth/login_user.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone: phone.value.trim(),
          password: password.value.trim(),
        }),
      });

      const data = await response.json();

      if (data.status === "success") {
        navigate("/home"); 
        window.location.reload(); 

      } else { 
        setLoginError(data.message || t("Login.unexpectedError"));
      }
    } catch (error) {
      console.error("Login error:", error);
      setLoginError(t("Login.unexpectedError"));
    } finally {
      setLoading(false); // Stop loading after request is complete
    }
  };

  return (
    <div className="login-container" style={{ direction: getDirection() }}>
      <div className="login-form-container">
        <div className="title">
          <p className="TitlContw" style={{ color: "#072C7B" }}>
            {t("Login.Login")}
          </p>
        </div>
        <form onSubmit={handleLogin} className="login-form">
          <div className="user-box">
            <label htmlFor="phone" style={{ color: "#072C7B" }}>
              {t("Login.phone")}
            </label>
            <Input
              id="phone"
              name="phone"
              placeholder={t("Login.placeholdersPhone")}
              type="tel"
              required
              inputProps={{ maxLength: 10, pattern: "^05\\d{8}$" }} // Saudi phone format validation
            />
          </div>
          <div className="user-box">
            <label htmlFor="password" style={{ color: "#072C7B" }}>
              {t("Login.password")}
            </label>
            <Input
              type="password"
              id="password"
              name="password"
              placeholder={t("Login.password")}
              required
            />
          </div>
          {loginError && (
            <p className="error-message">
              <ErrorOutlineIcon style={{ marginRight: "5px" }} />
              {loginError}
            </p>
          )}
          <div style={{ margin: "20px 0" }}>
            <button
              variant="outlined"
              startIcon={loading ? <CircularProgress size={20} /> : <LoginIcon />}
              type="submit"
              className="Registration-btn"
              disabled={loading} 
              fullWidth
              style={{ backgroundColor: loading ? "#f0f0f0" : "" }}
            >
              {loading ? t("Login.loading") : t("Login.Login2")}
            </button>
          </div>
        </form>
        <Link to="/signup">
          <Button variant="text" color="primary" className="forget-password-btn" fullWidth>
            <p className="titlenormalcolapsText">{t("Login.idha")}</p>
          </Button>
        </Link> 
        <br />
        <Link to="/forget">
          <Button variant="text" color="primary" className="forget-password-btn" fullWidth>
            <p className="titlenormalcolapsText">{t("Login.forget")}</p>
          </Button>
        </Link>
      </div>
    </div>
  );
};
 
export default Login;
