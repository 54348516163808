import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Slider from 'react-slick';
import { useSpring, animated } from 'react-spring';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import { useAuthContext } from "./context/AuthContext";
import Solution from "./pages/Solution.js";
import './Purchase.css';
import imageA from './components/images/image_without.png';
import icon1Img from './components/images/icon1.png';
import icon2Img from './components/images/icon2.png';
import Slideshow from "./pages/Slideshow";
import PhoneList from './PhoneList';
import CableList from './CabelList';
import CallList from './CallList';
import AddOnServices from './AddOnServices.js';
import BeforeList from './BeforeList.js';

const Purchase = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { user } = useAuthContext();
  const { t, i18n } = useTranslation("global");
  const [cartItems, setCartItems] = useState([]);
  const [additionalServices, setAdditionalServices] = useState([]);
  const [openSection, setOpenSection] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('switch');
  const [openArticle, setOpenArticle] = useState(null);

  const toggleSection = (section) => {
    setOpenSection(current => current === section ? null : section);
  };

  const toggleArticle = (article) => {
    setOpenArticle(current => current === article ? null : article);
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const dataUrl = i18n.language === "ar"
          ? 'https://lahalak.sa/backend/json_files/translation/Ar/additionalServices.json'
          : 'https://lahalak.sa/backend/json_files/translation/En/additionalServices.json';

        const response = await fetch(dataUrl);
        const data = await response.json();
        setAdditionalServices(data);
      } catch (error) {
        console.error("Error loading additional services data:", error);
      }
    };
    loadData();
  }, [i18n.language]);

  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const categories = [
    { Title: ' ', value: 'switch', image: t("Purchase.Pur1") },
    { Title: ' ', value: 'phone', image: t("Purchase.Pur4") },
    { Title: ' ', value: 'AddOnServices', image: t("Purchase.Pur4_2") },
  ];

  const addToCart = (item) => {
    setCartItems((prevCartItems) => {
      const existingItem = prevCartItems.find((cartItem) => cartItem.id === item.id);
      if (existingItem) {
        return prevCartItems.map((cartItem) =>
          cartItem.id === item.id ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
        );
      }
      return [...prevCartItems, { ...item, quantity: 1 }];
    });
  };

  const removeItem = (itemId) => {
    setCartItems(cartItems.filter((cartItem) => cartItem.id !== itemId));
  };

  const getTotal = () => {
    return cartItems.reduce((total, cartItem) => total + (cartItem.Price * cartItem.quantity), 0);
  };

  const renderCartItem = (item) => (
    <div className="carto" key={item.id} dir={getDirection(i18n.language)}>
      <div className="cartfo">
        <img src={item.image1} alt={`Product: ${item.Title}`} className="cartmage" />
        <div>
          <p className="cart-item-title">{item.Title}</p>
          <p className="cart-item-price">{item.Price}</p>
          <p className="cart-item-quantity">Quantity: {item.quantity}</p>
        </div>
      </div>
      <div className="cart-item-actions">
        <button onClick={() => removeItem(item.id)}>Remove</button>
      </div>
    </div>
  );

  const renderPage = () => {
    switch (selectedCategory) {
      case 'phone': return <PhoneList addToCart={addToCart} />;
      case 'switch': return <CallList addToCart={addToCart} />;
      case 'cabel': return <CableList addToCart={addToCart} />;
      case 'before': return <BeforeList addToCart={addToCart} />;
      case 'AddOnServices': return <AddOnServices addToCart={addToCart} />;
      default: return null;
    }
  };

  const iconSliderRef = useRef(null);
  const iconData = [
    {
      id: "1000",
      img: icon1Img,
      title: t("AdditionalS.title1"),
      text: t("AdditionalS.text1"),
    },
    {
      id: "1001",
      img: icon2Img,
      title: t("AdditionalS.title2"),
      text: t("AdditionalS.text2"),
    },
  ];

  const iconSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
    accessibility: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  const viewProps = {
    user, t, i18n, categories, setSelectedCategory, additionalServices, selectedCategory,
    renderPage, cartItems, renderCartItem: (item) => renderCartItem(item), iconData, iconSettings, iconSliderRef
  };

  return (
    <div>
      {isMobile ? <MobileView {...viewProps} /> : <DesktopView {...viewProps} />}
      <div className='bluebodye' >
        <div className='marvelbodye' >
          <br />
          <h1 className="TitlContw">{t("Purchase.Purkaf")}</h1>
          <h1 className="TitlContKhafet">{t("Purchase.Pur19")}</h1>
        </div>

        <Solution />
      </div>
      <div className='bluebodye'>
        <Slideshow />

        <div className="bahite whiteDbodye" dir={getDirection(i18n.language)}>
          <div className='why'>
            <p className="warda">{t("Buying_guide.FAQs")}</p>
          </div>
          <div>
            <div className="articleContent whiteH">
              <button className="button-collapses" onClick={() => toggleArticle('FAQs1')} aria-expanded={openArticle === 'FAQs1'}>
                <animated.span style={{ transform: openArticle === 'FAQs1' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                  {openArticle === 'FAQs1' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </animated.span>
                <p className="textCont">{t("Buying_guide.FAQs1")}</p>
              </button>
              {openArticle === 'FAQs1' && (
                <animated.div style={{
                  backgroundColor: '#F4F6FA',
                  borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                }}>
                  <p className="normalcolapsText">{t("Buying_guide.FAQs2")}</p>
                </animated.div>
              )}
              {openArticle !== 'FAQs1' && <div className="line"></div>}
            </div>

            <div className="articleContent whiteH">
              <button className="button-collapses" onClick={() => toggleArticle('subFAQs8')} aria-expanded={openArticle === 'subFAQs8'}>
                <animated.span style={{ transform: openArticle === 'subFAQs8' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                  {openArticle === 'subFAQs8' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </animated.span>
                <p className="textCont">{t("Buying_guide.FAQs5")}</p>
              </button>
              {openArticle === 'subFAQs8' && (
                <animated.div style={{
                  backgroundColor: '#F4F6FA',
                  borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                }}>
                  <p className="normalcolapsText">{t("Buying_guide.FAQs4")}</p>
                </animated.div>
              )}
              {openArticle !== 'subFAQs8' && <div className="line"></div>}
            </div>

            <div className="articleContent whiteH">
              <button className="button-collapses" onClick={() => toggleArticle('ourSpecificationend1')} aria-expanded={openArticle === 'ourSpecificationend1'}>
                <animated.span style={{ transform: openArticle === 'ourSpecificationend1' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                  {openArticle === 'ourSpecificationend1' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </animated.span>
                <p className="textCont">{t("Buying_guide.FAQs3")}</p>
              </button>
              {openArticle === 'ourSpecificationend1' && (
                <animated.div style={{
                  backgroundColor: '#F4F6FA',
                  borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                }}>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot1")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot2")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot3")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot5")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot4")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot6")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot7")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot8")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot9")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot10")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot11")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot12")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot13")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot14")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot15")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot16")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot17")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot18")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot19")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot20")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot21")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot22")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot23")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot24")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot25")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot26")}</p>
                  <div className="backgroundsnowwhite">
                    <div className="columncontact ">
                      <p className="titlenormalcolapsText">{t("Buying_guide.tot27")}</p>
                      <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot28")}</p>

                      <div className="habaa">
                        <Link to="/SendMessage" state={{ MessagTitle: "تواصل" }}>
                          <button className="sendbtnOne sendbtn">
                            {t("Buying_guide.tot29")}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </animated.div>
              )}
              {openArticle !== 'ourSpecificationend1' && <div className="line"></div>}
            </div>

            <div className="articleContent whiteH">
              <button className="button-collapses" onClick={() => toggleArticle('subFAQs10')} aria-expanded={openArticle === 'subFAQs10'}>
                <animated.span style={{ transform: openArticle === 'subFAQs10' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                  {openArticle === 'subFAQs10' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </animated.span>
                <p className="textCont">{t("Buying_guide.FAQs7")}</p>
              </button>
              {openArticle === 'subFAQs10' && (
                <animated.div style={{
                  backgroundColor: '#F4F6FA',
                  borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                }}>
                  <p className="normalcolapsText">{t("Buying_guide.FAQs8")}</p>
                </animated.div>
              )}
              {openArticle !== 'subFAQs10' && <div className="line"></div>}
            </div>

            <div className="articleContent whiteH">
              <button className="button-collapses" onClick={() => toggleArticle('subFAQs11')} aria-expanded={openArticle === 'subFAQs11'}>
                <animated.span style={{ transform: openArticle === 'subFAQs11' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                  {openArticle === 'subFAQs11' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </animated.span>
                <p className="textCont">{t("Buying_guide.FAQs9")}</p>
              </button>
              {openArticle === 'subFAQs11' && (
                <animated.div style={{
                  backgroundColor: '#F4F6FA',
                  borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                }}>
                  <p className="normalcolapsText">{t("Buying_guide.FAQs10")}</p>
                </animated.div>
              )}
              {openArticle !== 'subFAQs11' && <div className="line"></div>}
            </div>

            <div className="articleContent whiteH">
              <button className="button-collapses " onClick={() => toggleArticle('subFAQs12')} aria-expanded={openArticle === 'subFAQs12'}>
                <animated.span style={{ transform: openArticle === 'subFAQs12' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                  {openArticle === 'subFAQs12' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </animated.span>
                <p className="textCont">{t("Buying_guide.FAQs11")}</p>
              </button>
              {openArticle === 'subFAQs12' && (
                <animated.div style={{
                  backgroundColor: '#F4F6FA',
                  borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                }}>
                  <p className="normalcolapsText">{t("Buying_guide.FAQs12")}</p>
                </animated.div>
              )}
            </div>
          </div>
        </div>
        <div className="backgroundsnowwhite" dir={getDirection(i18n.language)}>
          <div className="columncontact">
            <div className="snow">
              <br />
            </div>

            <p className="warda">{t("Buying_guide.1")}</p>
            <div className="snow">
              <p className="titlenormalcolapsTextULX">{t("Buying_guide.tit101")}</p>
              <br />
              <div className="buttonRow">
                <div className="widthbutton">
                  <Link to="/SendMessage" state={{ MessagTitle: "اطرح سؤالا" }}>
                    <button className="blueNewTkhile">
                      {t("faq.sendyouropinion")}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DesktopView = ({ user, t, i18n, categories, setSelectedCategory, selectedCategory, additionalServices, renderPage, cartItems, renderCartItem, iconData, iconSettings, iconSliderRef }) => {
  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };

  const Icon = ({ img, title, text, id }) => (
    <div className="cart">
      <Link to="/Service_provider" state={{ id: id, image: img }}>
        <img src={img} alt={title} className="cart-img-top" />
        <div className="cartsbody">
          <h5 className="cartstitle">{title}</h5>
          <p className="cartstext">{text}</p>
        </div>
      </Link>
    </div>
  );

  return (
    <div className={`app-container ${i18n.language === "ar" ? "rtl" : "ltr"}`} dir={getDirection(i18n.language)}>
      <div className='bluebody'>
        <div className="">
          <div className="bannernaksol" style={{
            background: 'linear-gradient(#FFFFFF,#FFFFFF)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100px',
            position: 'relative',
            textAlign: 'center',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
          }}>
            <br />
            <div className="titlealacontainer">
              <p className="TitlCont">{t("Purchase.title")}</p>
              <p className="TitlConta">{t("Purchase.title12")}</p>
            </div>
            <img src={imageA} alt="Decorative"
              style={{ position: 'absolute', bottom: '10px', left: '100px', width: '200px', height: 'auto', zIndex: 1 }} />
            <div className="categorycntainerContainer">
              <div className="categorycntainer ">
                {categories
                  .map((category) => (
                    <div
                      key={category.value}
                      onClick={() => setSelectedCategory(category.value)}
                      className={`categoryitem ${selectedCategory === category.value ? 'selected' : ''}`}
                    >
                      <p>{category.image}</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="page-content">
            <br />
            <br />
            <br />
            <br />
            {renderPage()}
            <br />
            <div className='Additional'>
              <br />
              <h1 className="TitlContw">
                {t("AdditionalS.Title")}
              </h1>
              <p className='TitlContKhafet  text-center'>
                {t("AdditionalS.txtcaption")}
              </p>
              <br />
              {/* <Slider {...iconSettings} ref={iconSliderRef}>
                {iconData.map((icon, index) => (
                  <div key={index} className="icSlide">
                    <Icon {...icon} />
                  </div>
                ))}
              </Slider> */}
              <br />
              <div className="aligne">
                <button className="blueNewTkhile" onClick={() => {
                  setSelectedCategory('AddOnServices');
                  window.scrollTo(0, 0);
                }}>
                  {t("AdditionalS.more")}
                </button>
              </div>
              <br />
              <br />
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

const MobileView = ({ user, t, i18n, iconSettings, iconSliderRef, iconData, categories, setSelectedCategory, renderPage, cartItems, selectedCategory, renderCartItem, additionalServices, openArticle, openSection, toggleArticle, toggleSection }) => {
  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };

  const Icon = ({ img, title, text, id }) => (
    <div className="cart">
      <Link to="/Service_provider" state={{ id: id, image: img }}>
        <img src={img} alt={title} className="cart-img-top" />
        <div className="cartsbody">
          <h5 className="cartstitle">{title}</h5>
          <p className="cartstext">{text}</p>
        </div>
      </Link>
    </div>
  );

  return (
    <div className={`app-containerrr ${i18n.language === "ar" ? "rtl" : "ltr"}`} dir={getDirection(i18n.language)}>
      <div>
        <div style={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: 'auto',
          position: 'relative',
          textAlign: 'center',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <div className="khayen">
            <div className="categorycntainerPhone">
              {categories
                .map((category) => (
                  <div
                    key={category.value}
                    onClick={() => setSelectedCategory(category.value)}
                    className={`categoryitemPhone ${selectedCategory === category.value ? 'selected' : ''}`}
                  >
                    <p>{category.image}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className='maxcoffe'>
          {renderPage()}
          <br />
          <div className='Additional'>
            <h1 className="TitlContw">
              {t("AdditionalS.Title")}
            </h1>
            <p className='TitlContKhafet  text-center'>
              {t("AdditionalS.txtcaption")}
            </p>
            <br />


            {/* 
            <Slider {...iconSettings} ref={iconSliderRef}>
              {iconData.map((icon, index) => (
                <div key={index} className="icSlide">
                  <Icon {...icon} />
                </div>
              ))}
            </Slider> */}
            <br />
            <div className="aligne">
              <button className="blueNewTkhile" onClick={() => {
                setSelectedCategory('AddOnServices');
                window.scrollTo(0, 0);
              }}>
                {t("AdditionalS.more")}
              </button>
              <br />
            </div>
            <br />
          </div>
          <div className='marvelbodye' >
            <h1 className="TitlContw">{t("Purchase.Pur18")}</h1>
            <h1 className="TitlContKhafet">{t("Purchase.Pur19")}</h1>
          </div>
        </div>
        <div className="page_CCcontent">
          <div className="centreacto">
            <br />
            <br /> 
            <div className='Desplays'>
              {additionalServices.slice(0, 4).map((service, index) => (
                <div className={`cardContactUE fourCard${index + 1}`} key={service.id}>
                  <Link to="/DetailsAdd" state={{ id: service.id }}>
                    <img className="cartoimgotope" src={service.image} alt="Service Visual Representation" />
                    <h5 className="TitleConta">{service.title}</h5>
                    <p className="textBCont">{service.summary}</p>
                  </Link>
                </div>
              ))}
            </div>
            <br />
            <div className="centreacto" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
              <button onClick={() => {
                setSelectedCategory('AddOnServices');
                window.scrollTo(0, 0);
              }} className="liquidMoney">
                {t("Purchase.Pur4_1")}
              </button>
            </div>




            <br />
          </div>
          <div className="shopping-cart-container">
            {cartItems.length === 0 ? (
              <p></p>
            ) : (
              cartItems.map((item) => renderCartItem(item))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Purchase;
