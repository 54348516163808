import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import './DetailsAdd.css';
import { useTranslation } from 'react-i18next';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, Share as ShareIcon } from '@mui/icons-material'; // Import Share Icon
import { useSpring, animated } from 'react-spring';

const DetailsAdd = () => { 
    const { t, i18n } = useTranslation("global");
    const [additionalServicesData, setAdditionalServicesData] = useState([]);
    const getDirection = () => i18n.language === "ar" ? "rtl" : "ltr";
    const location = useLocation();
    const id = new URLSearchParams(location.search).get('id'); // Get id from URL query string
    const [openSection, setOpenSection] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const loadData = async () => {
            try {
                const dataUrl = i18n.language === "ar"
                    ? 'https://lahalak.sa/backend/json_files/translation/Ar/additionalServices.json'
                    : 'https://lahalak.sa/backend/json_files/translation/En/additionalServices.json';

                const response = await fetch(dataUrl);
                const data = await response.json();
                setAdditionalServicesData(data);
            } catch (error) {
                console.error('Error loading data:', error);
            }
        };
        loadData();
    }, [i18n.language]);

    // Share link function using Web Share API
    const shareLink = () => {
        const shareData = {
            title: document.title,
            text: "Check out this service:",
            url: window.location.href, // Share the current URL
        };

        if (navigator.share) {
            navigator.share(shareData)
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing:', error));
        } else {
            alert("Your browser doesn't support the Share API.");
        }
    };

    const toggleSection = (index) => {
        setOpenSection(prev => prev === index ? null : index);
    };

    const renderContent = (content) => {
        if (Array.isArray(content) && content.length > 0) {
            if (typeof content[0] === 'object' && content[0] !== null) {
                return (
                    <ul>
                        {content.map((item, idx) => (
                            <li key={idx}>
                                {item.icon && <i className={`fa ${item.icon}`} aria-hidden="true"></i>} {item.text}
                            </li>
                        ))}
                    </ul>
                );
            } else {
                return (
                    <ul>
                        {content.map((item, idx) => <li key={idx}>{item}</li>)}
                    </ul>
                );
            }
        }
        return <p>{content}</p>;
    };

    const service = additionalServicesData.find(item => item.id === id);

    if (!service) {
        return <p>Loading...</p>; 
    }

    return (
        <div className='backgroundblue'>
            <div className={`${getDirection()} backgroundwhitelana`} style={{ direction: getDirection() }}>
                
                {/* Banner section */}
                <div className="bannersollana" style={{
                    backgroundImage: `url(${service.image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '300px',
                    position: 'relative',
                    textAlign: 'center'
                }}>
                    {/* Share Button */}
                    <button
                        onClick={shareLink}
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            backgroundColor: '#0056B3',
                            border: 'none',
                            borderRadius: '50%',
                            color: 'white',
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                    >
                        <ShareIcon style={{ color: 'white' }} />
                    </button>
                </div>

                <div className="detailsContent" dir={getDirection(i18n.language)}>
                    <p className="TitlContFatenlana">{service.title}</p>

                    <p className="summaryText">{service.description}</p>
                    {service.subsummary && <p className='subsummary'>{service.subsummary}</p>}

                    {service.sections.map((section, index) => (
                        <div key={index} className="section">
                            <button className="button-collapsess" onClick={() => toggleSection(index)} aria-expanded={openSection === index}>
                                <animated.span style={{ transform: openSection === index ? 'rotate(180deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                    {openSection === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </animated.span>
                                <p className='titlecollaps'>{section.title}</p>
                            </button>
                            {openSection === index && (
                                <animated.div style={{
                                    backgroundColor: '#F4F6FA',
                                    borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)',
                                    transition: 'max-height 0.5s ease-in-out',
                                    overflow: 'hidden'
                                }}>
                                    <div className="sectionContent titlecollapsanouch" dir={getDirection(i18n.language)}>
                                        {renderContent(section.content)}
                                        {section.table && (
                                            <table className="custom-table">
                                                <thead>
                                                    <tr>
                                                        {section.table.headers.map((header, headerIndex) => (
                                                            <th key={headerIndex}>{header}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {section.table.data.map((row, rowIndex) => (
                                                        <tr key={rowIndex}>
                                                            {row.map((cell, cellIndex) => (
                                                                <td key={cellIndex} data-label={section.table.headers[cellIndex]}>{cell}</td>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )}
                                    </div>
                                </animated.div>
                            )}
                        </div>
                    ))}
                    <br />
                    <div className='divhelped'>
                        <p className='TitlContFatenlanaBB'>{service.footer.callToActionTitle}</p>

                        <div className='div2helped'>
                            <p>{service.footer.callToActionDescription}</p>
                        </div> 
                    </div>
                    <div className="text-center">
                        <Link to="/HospitalServiceAdd" state={{ id: service.id, image: service.image }}>
                            <button className="blueNewTkhile">عاين مزودي الخدمة</button>
                        </Link>
                    </div>
                    <br /> 
                    <br />
                </div>

            </div>
        </div>

    );
};

export default DetailsAdd;
