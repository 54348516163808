import React, { useEffect, useState, useRef } from "react";
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';

import './Navbare.css';
import LanguageIcon from '@mui/icons-material/Language';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

const Navbar = () => {
  const { user } = useAuthContext();
  const { t, i18n } = useTranslation("global"); // Initialize the translation hook
  const [nav, setNav] = useState(false);
  const [isConverted, setIsConverted] = useState(false);
  const navRef = useRef(); // Create a ref for the navbar

  const handleNav = () => {
    setNav(!nav);
  };

  const closeNav = () => {
    setNav(false);
  };

  const handleClick = () => {

    const newLanguage = isConverted ? 'ar' : 'en'; // Toggle between 'en' and 'ar'
    i18n.changeLanguage(newLanguage)
      .then(() => {
        console.log(`Language changed to ${newLanguage}`);
      })
      .catch((error) => {
        console.error('Error changing language:', error);
      });
    setIsConverted(!isConverted);
    closeNav();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (nav && navRef.current && !navRef.current.contains(e.target)) {
        setNav(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [nav]);



  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };

  return (
    <div ref={navRef} className='navbare flex justify-between items-center max-w-[1240px] mx-auto px-4 text-white relative z-10' style={{ direction: getDirection() }}>
      <div style={{ display: 'flex' }}>
        <h1 className='text-3xl font-bold text-[#00df9a]'>
          <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="images/loba.png"
              alt="Description of the image"
              className="navbar-logo"
            />
          </Link>
        </h1>
        <div className="triabel">افتتاح تجريبي</div>

      </div>

      <ul className='hidden md:flex' style={{ fontSize: '18px' }}>

        <li className='p-3'>
          <Link to="/"  style={{ color: 'white' }} onClick={closeNav}>
            {t("Navbar.Shop")}

          </Link>
        </li>
        <li className='p-3'>
          <Link to="/automation" style={{ color: 'white' }}>
            {t("Navbar.Solutions")}
          </Link>
        </li>
        <li className='p-3'>
          <Link to="/Buying_guide" style={{ color: 'white' }} onClick={closeNav}>
            {t("Navbar.dalilshira")}

          </Link>
        </li>
        <li className='p-3'>
          <Link to="/Latestnews" style={{ color: 'white' }}>
            {t("Navbar.News")}
          </Link>
        </li>
        <li className='p-3'>
          <Link to="/support" style={{ color: 'white' }}>
            {t("Navbar.Support")}
          </Link>
        </li>


        <li className='p-3'>
          <Link to="/contact_us" style={{ color: 'white' }}>
            {/* Contact&nbsp;Us */}
            {t("Navbar.Contact")}

          </Link>
        </li>

        {/* <li className='p-4'>
          <Link to="/ourclient" style={{ color: 'white' }}>
            {t("Navbar.Client")}

          </Link>
        </li> */}


<div className="hidden md:flex moreCloser">
  <li className='p-3'>
    <button className={`buttontr ${isConverted ? 'blackButton' : ''}`} onClick={handleClick}>
      <LanguageIcon /> <p className="redcoffe"> {isConverted ? 'Arabic' : 'Eng'}</p>
    </button>
  </li>
  <li className='p-3'>
    <Link to={user ? "/Home" : "/signup"} style={{ color: 'white' }} onClick={closeNav}>
      <button className={`buttontr ${isConverted ? 'blackButton' : ''}`}>
        <PersonOutlineIcon /><p className="redcoffe"> {user ? t("Navbar.login2") : t("Navbar.login1")}</p>
      </button>
    </Link>
  </li>
</div>

        {/* <li className='p-4'>
          <button>
            <img
              src="images/3455.png"
              alt="Description of the image"
              className="mr-4"
            />
          </button>
        </li> */}
      </ul>

      <div onClick={handleNav} className='block md:hidden'>
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>

      <ul className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#072C7B] ease-in-out duration-500 z-20' : 'ease-in-out duration-500 fixed left-[-100%]'} style={{ fontSize: '16px' }}  >
        <br />
        <br />
        <div className="logo-container">
          <Link to="/" onClick={closeNav}>
            <img
              src="images/loba.png"
              alt="Description of the image"
              className="navbar-logo"
              style={{ height: 'auto', width: '50px' }}
            />
          </Link>

        </div>
        <br />
        <br />
        <br />
        <br />


        <li className='p-4'>
          <Link to= "/" style={{ color: 'white' }} onClick={closeNav}>
            {t("Navbar.Shop")}

          </Link>
        </li>
        <li className='p-4'>
          <Link to="/automation" style={{ color: 'white' }} onClick={closeNav}>

            {t("Navbar.Solutions")}

          </Link>
        </li>
        <li className='p-4'>
          <Link to="/Buying_guide" style={{ color: 'white' }} onClick={closeNav}>
            {t("Navbar.dalilshira")}

          </Link>
        </li>
        <li className='p-4'>
          <Link to="/Latestnews" style={{ color: 'white' }} onClick={closeNav}>
            {t("Navbar.News")}

          </Link>
        </li>
        <li className='p-4'>
          <Link to="/support" style={{ color: 'white' }} onClick={closeNav}>
            {t("Navbar.Support")}

          </Link>
        </li>



        <li className='p-4'>
          <Link to="/contact_us" style={{ color: 'white' }} onClick={closeNav}>
            {t("Navbar.Contact")}

          </Link>
        </li>

        {/* <li className='p-4'>
          <Link to="/ourclient" style={{ color: 'white' }} onClick={closeNav}>
            {t("Navbar.Client")}
          </Link>
        </li> */}


        <div>
          <li className='p-4'>
            <button className={`buttontr ${isConverted ? 'blackButton' : ''}`}
              onClick={handleClick}>
              <LanguageIcon /> {isConverted ? 'Arb' : 'Eng'}
            </button>
          </li>
          <li className='p-4'>
            <button className={`buttontr ${isConverted ? 'blackButton' : ''}`}
            >
              <Link to={user ? "/Home" : "/signup"} style={{ color: 'white' }} onClick={closeNav}>
                <PersonOutlineIcon /> {user ? t("Navbar.login2") : t("Navbar.login1")}
              </Link>
            </button>
          </li>
          {/* <li className='p-4'>
            <Link to={user ? "/home" : "/signup"} style={{ color: 'white' }} onClick={closeNav}>
              <button className={`buttontr ${isConverted ? 'blackButton' : ''}`} >
                <PersonOutlineIcon /> {user ? 'profile' : 'login'}
              </button>
            </Link>
          </li> */}
          {/* <li className='p-4'>
            <button>
              <img
                src="images/3455.png"
                alt="Description of the image"
                className="mr-4"
              />
            </button>
          </li> */}

          {/* <Link to="/" onClick={closeNav}>

          </Link> */}
        </div>
      </ul>
    </div>
  );
};

export default Navbar;
