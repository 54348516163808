

import './CabelList.css';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Pill from "./Pill";

const CabelList = ({ callData, addToCart }) => {
  const { t, i18n } = useTranslation("global");



  return (
    <div className="restricted-page">
      <h1 className="TitlContw">{t("Purchase.Pur2")}</h1>

      <h1 className="title">شبكة لها و لك الطبية</h1>
      <p className="message">انتظرونا قريبا وبامكانكم انهاء الاجراءات عبر زيارة مكاتب خدمة العملاء او التواصل معنا</p>
      <p className="contact-info">
        <strong>أرقام التواصل:</strong> 0571605516 - 0599489990
      </p>
      <p className="email-info">
        <strong>Email:</strong> info@lahalak.sa
      </p>
      <p className="address">
        <strong>جده:</strong> شارع فلسطين 6286، حي الحمراء، عمارة نورا 3738<br />
        <strong>الرياض:</strong> شارع الملك فيصل 15366، المربع، قاعه 4
      </p>
      <Pill />   

    </div>

  );

};
export default CabelList;


